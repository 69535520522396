import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import { Container, Stack, Typography, Card } from '@mui/material';
import { isEmpty } from "lodash";

// components
import Page from 'components/Page';
// service
import { getEmployeeType, updateEmployeeType } from "services/employeeType.service";

import EmployeeTypeForm from "./EmployeeTypeForm";

const EditEmployeeType = () => {
  const history = useHistory();

  const { id } = useParams();
  const { isSuccess: isSuccessStatus, data: resStatus } = useQuery(["status-edit", { statusId: id }], getEmployeeType);
  
  const [finishLoad, setFinishLoad] = useState(false);
  const [statusState, setStatusState] = useState({});

  useEffect(() => {
    if (isSuccessStatus) {
      setStatusState(resStatus.data);
      setFinishLoad(true);
    }
  }, [isSuccessStatus, resStatus]);

  const submitStatus = useMutation(values => {
    return updateEmployeeType({values, statusId: id});
  });

  const onSubmitStatus = (values) => {
    submitStatus.mutate(values, {
      onSuccess: (res) => {
        if (res) {
          history.goBack();
        }
      }
    });
  }

  return (
    <Page title="Employee Type">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Edit Employee Type
          </Typography>
        </Stack>

        <Card>
          {
            (finishLoad === true && !isEmpty(statusState)) && (
              <EmployeeTypeForm 
                initialValues={statusState}
                onSubmit={onSubmitStatus}
              />
            )
          }
        </Card>
      </Container>
    </Page>
  );
}

export default EditEmployeeType;