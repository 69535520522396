import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useQuery, useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { Container, Stack, Typography, Card } from '@mui/material';

// components
import Page from 'components/Page';
// services
import { createUser } from "services/user.service";
import { getCorporates } from "services/userCorporate.service";
import { getApps } from "services/userApp.service";
import { getLicenses, assignLicense } from "services/userLicense.service";

import UserForm from "./UserForm";

const CreateUserForm = () => {
  const history = useHistory();
  const currentUser = useSelector((state) => state.userData?.currentUser);
  const selectedOrg = useSelector((state) => state.userData?.orgId);

  const { isSuccess: isSuccessCorp, data: resCorp } = useQuery(["corporates", { orgIds: currentUser?.organizations ?? [] }], getCorporates);
  const { isSuccess: isSuccessApp, data: resApp } = useQuery(["apps"], getApps); 
  const { isSuccess: isSuccessLicense, data: resLicense } = useQuery(["licenses", 
  { 
    ownerId: currentUser?.organizations, 
    userAccessControl: currentUser?.uId 
  }], 
  getLicenses, 
  {
    enabled: isSuccessApp
  });

  const [corps, setCorps] = useState([]);
  const [apps, setApps] = useState([]);
  const [avaialbleApps, setAvailableApps] = useState([]);

  const [finishLoadApp, setFinishLoadApp] = useState(false);
  const [finishLoad, setFinishLoad] = useState(false);

  useEffect(() => {
    if (isSuccessCorp && resCorp) {
      setCorps(resCorp.map(item => (
        {
          value: item.uId,
          label: item.corpName
        }
      )));
    }
  }, [isSuccessCorp, resCorp]);

  useEffect(() => {
    if (isSuccessApp) {
      setApps(resApp.map(item => (
        {
          value: item.code,
          label: item.name
        }
      )));
      setFinishLoadApp(true);
    }
  }, [isSuccessApp, resApp]);

  useEffect(() => {
    if (isSuccessLicense && resLicense) {
      const orgAccessControls = {};
      currentUser?.organizations?.map((x, index) => {
        orgAccessControls[x] = [];
        const licenses = resLicense.filter(l => l.ownerId === x);

        const eprofileApp = apps.find(a => a.value.toLowerCase() === "eprofile");
        if (eprofileApp) {
          orgAccessControls[x].push(eprofileApp);
        }

        licenses.map(l => {
          const licenseApp = apps.find(a => a.value === l.appAccessControl);
          if (licenseApp) {
            orgAccessControls[x].push(licenseApp);
          }
        })
      });

      setAvailableApps(orgAccessControls);
      setFinishLoad(true);
    }
  }, [isSuccessLicense, resLicense, finishLoadApp]);

  const submitUser = useMutation(values => {
    return createUser({values});
  });

  const submitLicense = useMutation(licenseValues => {
    return assignLicense({
      values: licenseValues
    })
  })

  const onSubmitUser = (values) => {
    submitUser.mutate(values, {
      onSuccess: (res) => {
        if (res) {
          const licenseValues = {
            userId: res.uId,
            adminOrganizations: currentUser?.organizations,
            organizations: JSON.parse(values.get("organizations")),
            orgAccessControls: JSON.parse(values.get("orgAccessControls"))
          }

          submitLicense.mutate(licenseValues, {
            onSuccess: (res) => {
              history.goBack();
            }
          })
        }
      }
    });
  }

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Create User
          </Typography>
        </Stack>

        <Card>
          {
            finishLoad && (
              <UserForm 
                corps={corps}
                apps={avaialbleApps}
                currentUser={currentUser}
                selectedOrg={selectedOrg}
                onSubmit={onSubmitUser}
              />
            )
          }
        </Card>
      </Container>
    </Page>
  );
}

export default CreateUserForm;