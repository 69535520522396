import React, { useMemo, useState, useEffect } from "react";
import { useQuery } from "react-query";
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
// material
import { 
  Stack, 
  TextField, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';
import plusCircle from '@iconify/icons-eva/plus-circle-outline';
import minusCircle from '@iconify/icons-eva/minus-circle-outline';
import cameraOutline from '@iconify/icons-eva/camera-outline';
import codes from "iso-language-codes"

import { getList } from "services/csc.service";
import { UploadWrapper } from "./styled";

// ----------------------------------------------------------------------

const UserForm = ({
  initialValues,
  onSubmit
}) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [fileImage, setFileImage] = useState(null);
  const [previewImage, setPreviewImage] = useState((initialValues.profile && initialValues.profile !== "") ? initialValues.profile : null);

  const UserSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    userName: Yup.string().required('Last Name is required'),
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string().required('Confirm Password is required'),
    email: Yup.string().required('Email is required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: UserSchema,
    onSubmit: (values) => hanldeSubmitFormik(values)
  });

  const { values, errors, touched, handleSubmit, getFieldProps } = formik;

  const { isSuccess: isSuccessCountry, data: resCountry } = useQuery(["coutries"], getList);
  const { isSuccess: isSuccessState, data: resState } = useQuery(
    ["states", { country_code: values.country }],
    getList,
    { enabled: values.country !== "" }
  );
  const { isSuccess: isSuccessCity, data: resCity } = useQuery(
    ["cities", { country_code: values.country, state_code: values.state }],
    getList,
    { enabled: values.state !== "" }
  );

  useEffect(() => {
    setPreviewImage((initialValues.profile && initialValues.profile !== "") ? initialValues.profile : null);
  }, [initialValues]);

  useEffect(() => {
    if (isSuccessCountry) {
      setCountries(resCountry?.data ?? []);
    }
  }, [isSuccessCountry, resCountry]);

  useEffect(() => {
    if (isSuccessState) {
      setStates(resState?.data ?? []);
    }
  }, [isSuccessState, resState]);

  useEffect(() => {
    if (isSuccessCity) {
      setCities(resCity?.data ?? []);
    }
  }, [isSuccessCity, resCity]);

  const optionLang = useMemo(() => {
    return codes.map((code) => (
      {
        name: code.name,
        value: code.iso639_1
      }
    ))
  }, []);

  const addAddress = (arrayHelpers) => {
    arrayHelpers.insert(getFieldProps("address").value.length, "");
  }

  const hanldeSubmitFormik = (values) => {
    const objValues = {
      ...values,
    }

    const formData = new FormData();
    Object.keys(objValues).filter(x => x !== "profile").map((field) => {
      if (
        field === "organizations" || 
        field === "appAccessControls" || 
        field === "address"
      ) {
        formData.append(field, JSON.stringify(objValues[field]));
      } else {
        formData.append(field, objValues[field]);
      }
    });

    if (fileImage) {
      formData.append("profile", fileImage);
    }

    onSubmit(formData);
  }  

  const onUploadProfile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = (e) => {
      setPreviewImage(reader.result);
    }

    setFileImage(e.target.files[0])
	}

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{m: 2}}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} style={{margin: "auto", marginBottom: "20px"}}>
            <UploadWrapper onClick={() => document.getElementById("inputProfile").click()}>
              <input hidden={true} id="inputProfile" type="file" onChange={(e) => onUploadProfile(e)} />
              <div>
                <Icon icon={cameraOutline} />
              </div>
              {
                previewImage && (
                  <img className="profile-pic" src={previewImage} alt="profile" />
                )
              }
            </UploadWrapper>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helpertext={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label="Last name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helpertext={touched.lastName && errors.lastName}
            />

            <TextField
              fullWidth
              label="User name"
              {...getFieldProps('userName')}
              error={Boolean(touched.userName && errors.userName)}
              helpertext={touched.userName && errors.userName}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              id="dateOfBirth"
              label="Date Of Birth"
              type="date"
              {...getFieldProps('dateOfBirth')}
              sx={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              fullWidth
              label="National ID"
              {...getFieldProps('nationalId')}
              error={Boolean(touched.nationalId && errors.nationalId)}
              helpertext={touched.nationalId && errors.nationalId}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helpertext={touched.email && errors.email}
            />

            <TextField
              fullWidth
              label="Contact No"
              {...getFieldProps('contactNo')}
              error={Boolean(touched.contactNo && errors.contactNo)}
              helpertext={touched.contactNo && errors.contactNo}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Password"
              type="password"
              {...getFieldProps('password')}
              error={Boolean(touched.password && errors.password)}
              helpertext={touched.password && errors.password}
            />

            <TextField
              fullWidth
              label="Confirm Password"
              type="password"
              {...getFieldProps('confirmPassword')}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              helpertext={touched.confirmPassword && errors.confirmPassword}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControl sx={{width: "50%"}}>
              <InputLabel>Country</InputLabel>
              <Select
                label="Country"
                {...getFieldProps('country')}
              >
                {
                  countries.map((opt, index) => (
                    <MenuItem key={index} value={opt.country_code}>{opt.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <FormControl sx={{width: "50%"}}>
              <InputLabel>State</InputLabel>
              <Select
                label="State"
                {...getFieldProps('state')}
              >
                {
                  states.map((opt, index) => (
                    <MenuItem key={index} value={opt.state_code}>{opt.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControl sx={{width: "100%"}}>
              <InputLabel>City</InputLabel>
              <Select
                label="City"
                {...getFieldProps('state')}
              >
                {
                  states.map((opt, index) => (
                    <MenuItem key={index} value={opt.name}>{opt.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>

            <TextField
              fullWidth
              label="Postal Code"
              {...getFieldProps('postalCode')}
              error={Boolean(touched.postalCode && errors.postalCode)}
              helpertext={touched.postalCode && errors.postalCode}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FieldArray
              name="address"
              render={arrayHelpers => (
                <div style={{width: "100%"}}>
                  <div style={{marginBottom: "10px", cursor: "pointer"}}>
                    <div 
                      style={{width: "200px", fontWeight: "bold"}} 
                      className="cs-pointer" 
                      onClick={() => addAddress(arrayHelpers)}
                    >
                      <Icon icon={plusCircle} style={{fontSize: "20px", position: "relative", top: "4px", marginRight: "10px"}} />
                      <span>Add Address</span>
                    </div>
                  </div>

                  {
                    (getFieldProps("address").value.length > 0) &&
                      getFieldProps("address").value.map((value, index) => (
                        <React.Fragment>
                          <TextField
                            key={index}
                            sx={{mb: 2, width: "90%"}}
                            label={`Address ${index + 1}`}
                            {...getFieldProps(`address.${index}`)}
                            error={Boolean(touched.address && errors.address)}
                            helpertext={touched.address && errors.address}
                          />
                          <Icon 
                            icon={minusCircle} 
                            style={{fontSize: "20px", position: "relative", top: "20px", left: "10px", cursor: "pointer"}} 
                            onClick={() => arrayHelpers.remove(index)}
                          />
                        </React.Fragment>
                      ))
                    }
                </div>
              )}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControl sx={{width: "50%"}}>
              <InputLabel>Language</InputLabel>
              <Select
                label="Language"
                {...getFieldProps('language')}
                error={Boolean(touched.language && errors.language)}
                helpertext={touched.language && errors.language}
              >
                {
                  optionLang.map((opt, index) => (
                    <MenuItem key={index} value={opt.value}>{opt.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormGroup>
              <InputLabel>Gender</InputLabel>
              <FormControlLabel control={<Checkbox defaultChecked />} label="Man" />
              <FormControlLabel control={<Checkbox />} label="Woman" />
            </FormGroup>
          </Stack>
        </Stack>
          <LoadingButton
            sx={{m: 2}}
            style={{display: "block"}}
            size="large"
            type="submit"
            variant="contained"
          >
            Update
          </LoadingButton>
      </Form>
    </FormikProvider>
  );
}

UserForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

UserForm.defaultProps = {
  initialValues: {
    firstName: "",
    lastName: "",
    userName: "",
    nationalId: "",
    organizations: [],
    appAccessControls: [],
    orgAccessControls: [],
    password: "",
    confirmPassword: "",
    email: "",
    city: "",
    postalCode: "",
    gender: "man",
    country: "SG",
    stage: "Singapore",
    language: "en",
    role: "admin",
    address: [""],
  },
  onSubmit: () => {}
};

export default UserForm;
