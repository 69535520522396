import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TableContainer,
} from '@mui/material';
// components
import Page from 'components/Page';
import Scrollbar from 'components/Scrollbar';
import TableList from 'components/tableList';
import ModalConfirmation from 'components/modalConfirmation';
import { UserMoreMenu } from 'components/_dashboard/user';
// services
import { getSites, deleteSite } from "services/site.service";

export default function Site() {
  const history = useHistory();
  const selectedOrg = useSelector((state) => state.userData?.orgId);

  const { isSuccess, data: resSite } = useQuery(["sites", { organizationId: selectedOrg }], getSites);

  const [sites, setSites] = useState([]);
  const [deleteId, setDeletedId] = useState("");
  const [show, setShow] = useState(false);
 
  useEffect(() => {
    if (isSuccess) {
      setSites(resSite?.data ?? []);
    }
  }, [isSuccess, resSite]);

  const columnSites = useMemo(() => {
    return [
      {
        name: "#",
        selector: (_, index) => index + 1,
        width: "80px"
      },
      {
        name: "Name",
        selector: row => row.name,
        sortable: true,
      },
      {
        name: "Phone No",
        selector: row => row.phoneNo,
      },
      {
        name: "Country",
        selector: row => row.country,
      },
      {
        name: "State",
        selector: row => row.state,
      },
      {
        name: "City",
        selector: row => row.city,
      },
      {
        name: "Address",
        selector: row => row.address,
        cell: row => (
          <div>
            {
              row.address?.map((address, index) => (
                <p key={index}>{address}</p>
              ))
            }
          </div>
        )
      },
      {
        name: "Action",
        button: true,
        cell: row => (
          <UserMoreMenu 
            onEdit={() => history.push(`/site/edit/${row.uId}`)}
            onDelete={() => {
              setDeletedId(row.uId);
              setShow(true);
            }}
          />
        )
      }
    ]
  }, [history]);

  const sourceSites = useMemo(() => {
    return sites.map((site, index) => (
      {
        key: index,
        uId: site.uId,
        _id: site._id,
        name: site.name,
        phoneNo: site.phoneNo,
        country: site.country,
        state: site.state,
        city: site.city,
        address: site.address
      }
    ))
  }, [sites]);

  const handleDelete = useCallback(async () => {
    const res = await deleteSite({deleteId});
    if (res) {
      setSites(sites.filter(x => x.uId !== deleteId));
    }

    setShow(false);
  }, [deleteId, sites]);

  return (
    <Page title="Sites Management">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Sites Management
          </Typography>

          
          <Button
            variant="contained"
            component={RouterLink}
            to="/site/create"
            startIcon={<Icon icon={plusFill} />}
          >
            New Site
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <TableList
                columns={columnSites}
                data={sourceSites}
                isFilter={true}
              />
            </TableContainer>
          </Scrollbar>
        </Card>

        <ModalConfirmation 
          open={show}
          handleClose={() => setShow(false)}
          handleOk={() => handleDelete()}
        />
      </Container>
    </Page>
  );
}
