import React from "react";
import PropTypes from "prop-types";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ModalConfirmation = ({
  content,
  open,
  handleClose,
  handleOk,
  props
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props}
    >
      <DialogTitle id="alert-dialog-title">
        {"Confirmation?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleOk} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalConfirmation.propTypes = {
  content: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleOk: PropTypes.func,
};

ModalConfirmation.defaultProps = {
  content: "Are you sure want to delete this ?",
  open: false,
  handleClose: () => {},
  handleOk: () => {},
};

export default ModalConfirmation;
