import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import mapFill from '@iconify/icons-eva/map-fill';
import monitorFill from '@iconify/icons-eva/monitor-fill';
import layerFill from '@iconify/icons-eva/layers-fill';
import calendarFilled from '@iconify/icons-ant-design/calendar-filled';
import hourglassFilled from '@iconify/icons-ant-design/hourglass-filled';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const userSidebarConfig = [
  {
    title: 'User',
    path: '/user',
    icon: getIcon(peopleFill)
  },
  {
    title: 'Corporate',
    path: '/corporate',
    icon: getIcon(monitorFill)
  },
  {
    title: 'Sites Management',
    path: '/site',
    icon: getIcon(mapFill)
  },
  {
    title: 'Department',
    path: '/department',
    icon: getIcon(layerFill)
  },
  {
    title: 'Holiday',
    path: '/holiday',
    icon: getIcon(calendarFilled)
  },
  {
    title: 'Employee Type',
    path: '/employeeType',
    icon: getIcon(hourglassFilled)
  },
];

export default userSidebarConfig;
