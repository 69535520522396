import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    currentUser: {},
    orgId: "",
  },
  reducers: {
    setLoginReducer: (state, action) => {
      state.currentUser = action.payload;
    },
    setLogoutReducer: (state, action) => {
      state.currentUser = {};
      state.orgId = "";
    },
    setOrgReducer: (state, action) => {
      state.orgId = action.payload;
    }
  },
})

export const { setLoginReducer, setLogoutReducer, setOrgReducer } = userSlice.actions;

export default userSlice.reducer;