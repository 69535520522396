import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import { Container, Stack, Typography, Card } from '@mui/material';
import { isEmpty } from "lodash";

// components
import Page from 'components/Page';
// service
import { getDepartment, updateDepartment } from "services/department.service";

import DepartmentForm from "./DepartmentForm";

const EditDepartmentForm = () => {
  const history = useHistory();

  const { id } = useParams();
  const { isSuccess: isSuccessDepartment, data: resDepartment } = useQuery(["department-edit", { departmentId: id }], getDepartment);
  
  const [finishLoad, setFinishLoad] = useState(false);
  const [departmentState, setDepartmentState] = useState({});

  useEffect(() => {
    if (isSuccessDepartment) {
      setDepartmentState(resDepartment.data);
      setFinishLoad(true);
    }
  }, [isSuccessDepartment, resDepartment]);

  const submitDepartment = useMutation(values => {
    return updateDepartment({values, departmentId: id});
  });

  const onSubmitDepartment = (values) => {
    submitDepartment.mutate(values, {
      onSuccess: (res) => {
        if (res) {
          history.goBack();
        }
      }
    });
  }

  return (
    <Page title="Department">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Edit Department
          </Typography>
        </Stack>

        <Card>
          {
            (finishLoad === true && !isEmpty(departmentState)) && (
              <DepartmentForm 
                initialValues={departmentState}
                onSubmit={onSubmitDepartment}
              />
            )
          }
        </Card>
      </Container>
    </Page>
  );
}

export default EditDepartmentForm;