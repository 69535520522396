import React from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { Container, Stack, Typography, Card } from '@mui/material';

// components
import Page from 'components/Page';
// services
import { createCorporate } from "services/adminCorporate.service";

import CorporateForm from "pages/shared/corporateForm";

const CreateCorporateForm = () => {
  const history = useHistory();
  
  const submitCorp = useMutation(values => {
    return createCorporate({values});
  });

  const onSubmitCorp = (values) => {
    const payload = {
      ...values,
      alias: values?.alias?.split(",")
    }

    submitCorp.mutate(payload, {
      onSuccess: (res) => {
        if (res)
          history.goBack();
      }
    });
  }

  return (
    <Page title="Corporate">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Create Corporate
          </Typography>
        </Stack>

        <Card>
          <CorporateForm 
            onSubmit={onSubmitCorp}
          />
        </Card>
      </Container>
    </Page>
  );
}

export default CreateCorporateForm;