import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
// material
import { 
  Stack, 
  TextField, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';
import plusCircle from '@iconify/icons-eva/plus-circle-outline';
import minusCircle from '@iconify/icons-eva/minus-circle-outline';

import { getList } from "services/csc.service";

const SiteForm = ({
  initialValues,
  onSubmit
}) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  let SiteSchema = Yup.object().shape({
    name: Yup.string().required('Site Name is required'),
    phoneNo: Yup.string().required('Site Phone Number is required'),
  });

  const hanldeSubmitFormik = (values) => {
    onSubmit(values);
  }

  const formik = useFormik({
    initialValues,
    validationSchema: SiteSchema,
    enableReinitialize: true,
    onSubmit: (values) => hanldeSubmitFormik(values)
  });

  const { values, errors, touched, handleSubmit, getFieldProps } = formik;

  const { isSuccess: isSuccessCountry, data: resCountry } = useQuery(["coutries"], getList);
  const { isSuccess: isSuccessState, data: resState } = useQuery(
    ["states", { country_code: values.country }],
    getList,
    { enabled: values.country !== "" }
  );
  const { isSuccess: isSuccessCity, data: resCity } = useQuery(
    ["cities", { country_code: values.country, state_code: values.state }],
    getList,
    { enabled: values.state !== "" }
  );

  useEffect(() => {
    if (isSuccessCountry) {
      setCountries(resCountry?.data ?? []);
    }
  }, [isSuccessCountry, resCountry]);

  useEffect(() => {
    if (isSuccessState) {
      setStates(resState?.data ?? []);
    }
  }, [isSuccessState, resState]);

  useEffect(() => {
    if (isSuccessCity) {
      setCities(resCity?.data ?? []);
    }
  }, [isSuccessCity, resCity]);

  const addAddress = (arrayHelpers) => {
    arrayHelpers.insert(getFieldProps("address").value.length, "");
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{m: 2}}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Site Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={(touched.name && errors.name) ? errors.name : ""}
            />
            <TextField
              fullWidth
              label="Phone No"
              {...getFieldProps('phoneNo')}
              error={Boolean(touched.phoneNo && errors.phoneNo)}
              helperText={(touched.phoneNo && errors.phoneNo) ? errors.phoneNo : ""}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControl sx={{width: "50%"}}>
              <InputLabel>Country</InputLabel>
              <Select
                label="Country"
                {...getFieldProps('country')}
              >
                {
                  countries.map((opt, index) => (
                    <MenuItem key={index} value={opt.country_code}>{opt.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <FormControl sx={{width: "50%"}}>
              <InputLabel>State</InputLabel>
              <Select
                label="State"
                {...getFieldProps('state')}
              >
                {
                  states.map((opt, index) => (
                    <MenuItem key={index} value={opt.state_code}>{opt.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControl sx={{width: "100%"}}>
              <InputLabel>City</InputLabel>
              <Select
                label="City"
                {...getFieldProps('city')}
              >
                {
                  cities.map((opt, index) => (
                    <MenuItem key={index} value={opt.name}>{opt.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>

            <TextField
              fullWidth
              label="Postal Code"
              {...getFieldProps('postalCode')}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FieldArray
              name="address"
              render={arrayHelpers => (
                <div style={{width: "100%"}}>
                  <div style={{marginBottom: "10px", cursor: "pointer"}}>
                    <div 
                      style={{width: "200px", fontWeight: "bold"}} 
                      className="cs-pointer" 
                      onClick={() => addAddress(arrayHelpers)}
                    >
                      <Icon icon={plusCircle} style={{fontSize: "20px", position: "relative", top: "4px", marginRight: "10px"}} />
                      <span>Add Address</span>
                    </div>
                  </div>

                  {
                    (getFieldProps("address").value.length > 0) &&
                      getFieldProps("address").value.map((value, index) => (
                        <React.Fragment>
                          <TextField
                            key={index}
                            sx={{mb: 2, width: "90%"}}
                            label={`Address ${index + 1}`}
                            {...getFieldProps(`address.${index}`)}
                          />
                          <Icon 
                            icon={minusCircle} 
                            style={{fontSize: "20px", position: "relative", top: "20px", left: "10px", cursor: "pointer"}} 
                            onClick={() => arrayHelpers.remove(index)}
                          />
                        </React.Fragment>
                      ))
                    }
                </div>
              )}
            />
          </Stack>
        </Stack>
          <LoadingButton
            sx={{m: 2}}
            style={{display: "block"}}
            size="large"
            type="submit"
            variant="contained"
          >
            Submit
          </LoadingButton>
      </Form>
    </FormikProvider>
  );
}

SiteForm.propTypes = {
  initialValues: PropTypes.object,
  apps: PropTypes.object,
  isEdit: PropTypes.bool,
  onSubmit: PropTypes.func,
};

SiteForm.defaultProps = {
  initialValues: {
    name: "",
    country: "SG",
    state: "",
    city: "",
    phoneNo: "",
    address: [""],
  },
  onSubmit: () => {}
};

export default SiteForm;
