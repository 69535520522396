import React from "react";
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
// material
import { 
  Stack, 
  TextField, 
  InputLabel, 
  FormGroup,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';
import plusCircle from '@iconify/icons-eva/plus-circle-outline';
import minusCircle from '@iconify/icons-eva/minus-circle-outline';

const DepartmentForm = ({
  initialValues,
  onSubmit
}) => {
  let DepartmentSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });

  const hanldeSubmitFormik = (values) => {
    onSubmit(values);
  }

  const formik = useFormik({
    initialValues,
    validationSchema: DepartmentSchema,
    enableReinitialize: true,
    onSubmit: (values) => hanldeSubmitFormik(values)
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const addJobPositions = (arrayHelpers) => {
    arrayHelpers.insert(getFieldProps("jobPositions").value.length, "");
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{m: 2}}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helpertext={touched.name && errors.name}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Description"
              multiline={true}
              minRows={4}
              {...getFieldProps('description')}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FieldArray
              name="jobPositions"
              render={arrayHelpers => (
                <div style={{width: "100%"}}>
                  <div style={{marginBottom: "10px", cursor: "pointer"}}>
                    <div 
                      style={{width: "200px", fontWeight: "bold"}} 
                      className="cs-pointer" 
                      onClick={() => addJobPositions(arrayHelpers)}
                    >
                      <Icon icon={plusCircle} style={{fontSize: "20px", position: "relative", top: "4px", marginRight: "10px"}} />
                      <span>Add Job Position</span>
                    </div>
                  </div>

                  {
                    (getFieldProps("jobPositions").value.length > 0) &&
                      getFieldProps("jobPositions").value.map((value, index) => (
                        <React.Fragment key={index}>
                          <TextField
                            key={index}
                            sx={{mb: 2, width: "90%"}}
                            label={`Job Position ${index + 1}`}
                            {...getFieldProps(`jobPositions.${index}`)}
                            error={Boolean(touched.jobPositions && errors.jobPositions)}
                            helpertext={touched.jobPositions && errors.jobPositions}
                          />
                          <Icon 
                            icon={minusCircle} 
                            style={{fontSize: "20px", position: "relative", top: "20px", left: "10px", cursor: "pointer"}} 
                            onClick={() => arrayHelpers.remove(index)}
                          />
                        </React.Fragment>
                      ))
                    }
                </div>
              )}
            />
          </Stack>
          <Stack>
            <FormGroup>
              <InputLabel>Status</InputLabel>
              <RadioGroup
                name="status"
                {...getFieldProps('status')}
              >
                <FormControlLabel value="active" control={<Radio defaultChecked />} label="Active" />
                <FormControlLabel value="inActive" control={<Radio />} label="Not Active" />
              </RadioGroup>
            </FormGroup>
          </Stack>
        </Stack>
        <LoadingButton
          sx={{m: 2}}
          style={{display: "block"}}
          size="large"
          type="submit"
          variant="contained"
        >
          Submit
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}

DepartmentForm.propTypes = {
  initialValues: PropTypes.object,
  apps: PropTypes.object,
  isEdit: PropTypes.bool,
  onSubmit: PropTypes.func,
};

DepartmentForm.defaultProps = {
  initialValues: {
    name: "",
    description: "",
    status: "active",
    jobPositions: [],
  },
  onSubmit: () => {}
};

export default DepartmentForm;
