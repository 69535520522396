import styled from "styled-components";

export const UploadWrapper = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #ddd;
  cursor: pointer;

  > div {
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    box-shadow: rgb(234, 234, 234) 0px 0px 10px 1px;
    background: white;
    border: 1px solid rgb(221, 221, 221);
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;

    svg {
      width: 35px;
      height: 35px;
      color: #8a8a8a;
    }
  }

  img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: #ddd;
    cursor: pointer;
  }
`;