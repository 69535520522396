import axios from "axios";
import axiosReq from "utils/axiosConfig";
import { API_URL } from "configures/setting";

import { alertError, alertSuccess } from "./alert.service";

export const login = async payload => {
  try {
    const res = await axiosReq.post(`/api/auth/login`, payload);
    return res;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const register = async payload => {
  try {
    const res = await axiosReq.post(`/api/auth/admin/register`, payload);
    alertSuccess(res?.data?.message ?? "Sucesss");
    return res;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const registerOrg = async payload => {
  try {
    const res = await axiosReq.post(`/api/auth/admin/corporate`, payload);
    return res;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const loginAdmin = async payload => {
  try {
    const res = await axiosReq.post(`/api/auth/admin/login`, payload);
    return res;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const verifyToken = async ({data}) => {
  try {
    const res = await axios.get(`${API_URL}/api/auth/checkLicense/${process.env.REACT_APP_NAME}`, {
      headers: {
        "Authorization": data.bearerToken
      }
    });
    return res;
  } catch(err) {
    alertError(err.response?.data?.message ?? "Action Fail");
  }
}

export const forgetPassword = async payload => {
  try {
    const res = await axios.post(`${API_URL}/api/auth/password/forgot`, payload);
    alertSuccess(res?.data?.message ?? "Sucesss");
    return res;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const verificationCode = async payload => {
  try {
    console.log(payload.authorization)
    const res = await axios.post(`${API_URL}/api/auth/verify/pincode`, payload, {
      headers: {
        "Authorization": payload.authorization
      }
    });
    return res;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const resetPassword = async payload => {
  try {
    const res = await axios.put(`${API_URL}/api/auth/password/reset`, payload, {
      headers: {
        "Authorization": payload.authorization
      }
    });
    alertSuccess("Reset password successfuly");
    return res;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}