import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Alert } from "@mui/material";
import { alertType, onAlert, clear } from "services/alert.service";

import { StyledAlert } from "./styles";

const AlertMessage = ({
  id,
}) => {
  const [alert, setAlert] = useState({});
  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;

    const subscription = onAlert(id).subscribe(notif => {
      if (isMountedRef.current) {
        setAlert(notif);
        setTimeout(() => setAlert({}), 3000);
      }
    });

    return () => {
      clear(id);
      subscription.unsubscribe();
      isMountedRef.current = false;
    };
  }, [id]);

  const getVariant = (alert) => {
    if (!alert) return;

    let variant;

    switch (alert.type) {
      case alertType.Success:
        variant = "success";
        break;
      case alertType.Error:
        variant = "error";
        break;
      case alertType.Warning:
        variant = "warning";
        break;
      case alertType.Info:
        variant = "info";
        break;
      default:
        variant = "info";
    }

    return variant;
  }

  return (
    <StyledAlert>
      {
        alert.message && (
          <Alert key="alert" severity={getVariant(alert)}>
            {alert.message}
          </Alert>
        )
      }
    </StyledAlert>
  )
};

AlertMessage.propTypes = {
  id: PropTypes.string,
};

AlertMessage.defaultProps = {
  id: "default-alert",
};

export default AlertMessage;
