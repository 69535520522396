import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TableContainer,
} from '@mui/material';
// components
import Page from 'components/Page';
import Scrollbar from 'components/Scrollbar';
import TableList from 'components/tableList';
import ModalConfirmation from 'components/modalConfirmation';
import { UserMoreMenu } from 'components/_dashboard/user';
// services
import { getDepartments, deleteDepartment } from "services/department.service";

export default function Department() {
  const history = useHistory();
  const selectedOrg = useSelector((state) => state.userData?.orgId);

  const { isSuccess, data: resDepartment } = useQuery(["departments", { organizationId: selectedOrg }], getDepartments);

  const [departments, setDepartments] = useState([]);
  const [deleteId, setDeletedId] = useState("");
  const [show, setShow] = useState(false);
 
  useEffect(() => {
    if (isSuccess) {
      setDepartments(resDepartment?.data ?? []);
    }
  }, [isSuccess, resDepartment]);

  const columnDepartments = useMemo(() => {
    return [
      {
        name: "#",
        selector: (_, index) => index + 1,
        width: "80px"
      },
      {
        name: "Name",
        selector: row => row.name,
        sortable: true,
      },
      {
        id: "Status",
        name: "Status",
        selector: row => row.status === "active" ? "Active" : "Not Active",
        sortable: true,
      },
      {
        name: "Action",
        button: true,
        cell: row => (
          <UserMoreMenu 
            onEdit={() => history.push(`/department/edit/${row.uId}`)}
            onDelete={() => {
              setDeletedId(row.uId);
              setShow(true);
            }}
          />
        )
      }
    ]
  }, [history]);

  const sourceDepartments = useMemo(() => {
    return departments.map((department, index) => (
      {
        key: index,
        uId: department.uId,
        _id: department._id,
        name: department.name,
        status: department.status,
      }
    ))
  }, [departments]);

  const handleDelete = useCallback(async () => {
    const res = await deleteDepartment({deleteId});
    if (res) {
      setDepartments(departments.filter(x => x.uId !== deleteId));
    }

    setShow(false);
  }, [deleteId, departments]);

  return (
    <Page title="Department">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Department
          </Typography>

          
          <Button
            variant="contained"
            component={RouterLink}
            to="/department/create"
            startIcon={<Icon icon={plusFill} />}
          >
            New Department
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <TableList
                columns={columnDepartments}
                data={sourceDepartments}
                defaultSortFieldId="Status"
                defaultSortAsc={false}
                isFilter={true}
              />
            </TableContainer>
          </Scrollbar>
        </Card>

        <ModalConfirmation 
          open={show}
          handleClose={() => setShow(false)}
          handleOk={() => handleDelete()}
        />
      </Container>
    </Page>
  );
}
