import axiosReq from "utils/axiosConfig";
import { alertError, alertSuccess } from "./alert.service";

export const getLicenses = async ({ queryKey }) => {
  try {
    const query = queryKey.length > 1 ? queryKey[1] : {};

    const res = await axiosReq.get(`/api/admin/licenses`, {
      params: { ...query }
    });
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}