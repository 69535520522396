import React from "react";
import { useSelector } from "react-redux";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { Container, Stack, Typography, Card } from '@mui/material';

// components
import Page from 'components/Page';
// services
import { createSite } from "services/site.service";

import SiteForm from "./SiteForm";

const CreateSiteForm = () => {
  const history = useHistory();
  const selectedOrg = useSelector((state) => state.userData?.orgId);

  const submitSite = useMutation(values => {
    return createSite({values: {...values, organizationId: selectedOrg}});
  });

  const onSubmitSite = (values) => {
    submitSite.mutate(values, {
      onSuccess: (res) => {
        if (res) {
          history.goBack();
        }
      }
    });
  }

  return (
    <Page title="Sites Management">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Create Site
          </Typography>
        </Stack>

        <Card>
          <SiteForm 
            onSubmit={onSubmitSite}
          />
        </Card>
      </Container>
    </Page>
  );
}

export default CreateSiteForm;