import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Drawer,
  Select,
  MenuItem,
} from '@mui/material';
// components
import LogoPNG from "assets/logo.png";
import Scrollbar from 'components/Scrollbar';
import NavSection from 'components/NavSection';
import { MHidden } from 'components/@material-extend';
import { getVersion } from "utils/common";

import { setOrgReducer } from "store/reducer/user.reducer";

import sidebarConfig from './SidebarConfig';
import userSidebarConfig from './UserSidebarConfig';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ role, isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.userData?.currentUser);
  const selectedOrg = useSelector((state) => state.userData?.orgId);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const handleChangeOrg = (value) => {
    dispatch(setOrgReducer(value));
  }

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      <Box sx={{ mt: 4, mb: 4, mx: 2.5 }}>
        <div style={{textAlign: "center"}}>
          <img alt="logo" src={LogoPNG} style={{width: "85px", margin: "auto"}} />
          <span style={{fontSize: "14px", fontWeight: "bold"}}>Version {getVersion()}</span>
        </div>
      </Box>

      {
        currentUser?.role !== "superAdmin" && (
          <Box sx={{mb: 2}} style={{textAlign: "center"}}>
            <Select
              label="Organizations"
              style={{width: "250px"}}
              value={selectedOrg}
              onChange={(e) => handleChangeOrg(e.target.value)}
            >
              {
                currentUser?.organizationDetails.map((opt, index) => (
                  <MenuItem key={index} value={opt.uId}>{opt.corpName}</MenuItem>
                ))
              }
            </Select>
          </Box>
        )
      }

      {
        currentUser?.role !== "user" && (
          <NavSection navConfig={role === "superAdmin" ? sidebarConfig : userSidebarConfig} />
        )
      }
 
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
