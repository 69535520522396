import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
// material
import { styled } from "@mui/material/styles";
import { Stack, Container } from "@mui/material";
// components
import LogoPNG from "assets/logo.png";
import Page from "components/Page";
import { LoginForm } from "components/authentication/login";
import { APP_NAME } from "configures/setting";
import { getVersion } from "utils/common";
// services
import { login } from "services/auth.service";
import { setLoginReducer, setOrgReducer } from "store/reducer/user.reducer";
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex"
  }
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmit = useCallback(async (values) => {
    const res = await login({
      ...values,
      app: APP_NAME
    });
    if (res && res.status === 200) {
      const { token } = res.data;
      localStorage.setItem("accessToken", token);
      localStorage.setItem("currentUser", JSON.stringify(res.data));
      dispatch(setLoginReducer(res.data))
      dispatch(setOrgReducer(res.data?.organizations?.length > 0 ? res.data?.organizations[0] : ""));

      if (res.data.role === "user") {
        history.push("/account");
      } else {
        history.push("/");
      }
    }
  }, [history]);

  return (
    <RootStyle title="Login">
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5, alignItems: "center", justifyContent: "center" }} align={"center"}>
            <img alt="logo" src={LogoPNG} style={{width: "85px"}} />
            <span style={{fontSize: "14px", fontWeight: "bold"}}>Version {getVersion()} </span>
          </Stack>
          <LoginForm isAdmin={false} onSubmit={onSubmit} />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
