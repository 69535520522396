import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TableContainer,
} from '@mui/material';
// components
import Page from 'components/Page';
import Scrollbar from 'components/Scrollbar';
import TableList from 'components/tableList';
import ModalConfirmation from 'components/modalConfirmation';
import { UserMoreMenu } from 'components/_dashboard/user';
import Label from 'components/Label';
// services
import { getUsers, deleteUser ,sendEmail} from "services/adminUser.service";

export default function User() {
  const history = useHistory();
  const { isSuccess, data } = useQuery(["users", { role: "admin", approve: false }], getUsers);

  const [users, setUsers] = useState([]);
  const [deleteId, setDeletedId] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setUsers(data);
    }
  }, [isSuccess, data]);

  const columnUsers = useMemo(() => {
    return [
      {
        name: "#",
        selector: (_, index) => index + 1,
        width: "80px"
      },
      {
        name: "First Name",
        selector: row => row.firstName,
        sortable: true,
      },
      {
        name: "Last Name",
        selector: row => row.lastName,
        sortable: true
      },
      {
        name: "Username",
        selector: row => row.userName,
        sortable: true
      },
      {
        name: "Email",
        selector: row => row.email,
      },
      {
        name: "Organization Name",
        selector: row => row.approvalOrgName,
      },
      {
        name: "UEN",
        selector: row => row.approvalOrgUEN,
      },
      {
        name: "Action",
        button: true,
        cell: row => (          
          <UserMoreMenu 
            isApproval={true}
            onEdit={() => history.push(`/admin/approval/edit/${row.uId}`, {
              approvalOrgName: row.approvalOrgName,
              approvalOrgUEN: row.approvalOrgUEN,
              dateOfIncorporation: row.dateOfIncorporation,
            })}
            onDelete={() => {
              setDeletedId(row.uId);
              setShow(true);
            }}
          />
        )
      }
    ]
  }, [history]);
  const sourceUsers = useMemo(() => {
    return users.map((user, index) => (
      {
        key: index,
        uId: user.uId,
        _id: user._id,
        firstName: user.firstName,
        lastName: user.lastName,
        userName: user.userName,
        email: user.email?.length > 0 ? user.email[0] : "",
        nationalId: user.nationalId,
        contactNo: user.contactNo,
        approvalOrgName: user.approvalOrgName,
        approvalOrgUEN: user.approvalOrgUEN,
        dateOfIncorporation: user.dateOfIncorporation,
        approve: user.approve ? "Approved" : "Rejected",
      }
    ))
  }, [users]);

  const handleDelete = useCallback(async () => {
    const res = await deleteUser({deleteId});
    if (res) {
      setUsers(users.filter(x => x.uId !== deleteId));
    }

    setShow(false);
  }, [deleteId, users]);

  const sendTo = async (userId) =>{
    await sendEmail(userId);
  }

  return (
    <Page title="User">
      <Container>

        
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Approval HR Admin
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <TableList
                columns={columnUsers}
                data={sourceUsers}
                isFilter={true}
              />
            </TableContainer>
          </Scrollbar>
        </Card>

        <ModalConfirmation 
          open={show}
          handleClose={() => setShow(false)}
          handleOk={() => handleDelete()}
        />
      </Container>
    </Page>
  );
}
