import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Stack, Container } from "@mui/material";
// components
import LogoPNG from "assets/logo.png";
import Page from "components/Page";
import { getVersion } from "utils/common";
// services
import { register, registerOrg } from "services/auth.service";
// form
import RegisterForm from "./RegisterForm";
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex"
  }
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 800,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Register() {
  const history = useHistory();

  const onSubmit = useCallback(async (values) => {
    registerOrg({
      corpName: values.corpName,
      uen: values.uen,
      dateOfIncorporation: values.dateOfIncorporation
    }).then((res) => {
      const organizations = res.data?.uId;
      register({
        ...values,
        appAccessControls: ["Eprofile"],
        organizations: organizations
      })
      .then((resUser) => {
        history.push("/login");
      })
    })
  }, [history]);

  const onCancel = () => {
    window.history.back();
  }

  return (
    <RootStyle title="Register">
      <Container maxWidth="md">
        <ContentStyle>
          <Stack sx={{ mb: 5, alignItems: "center", justifyContent: "center" }} align={"center"}>
            <img alt="logo" src={LogoPNG} style={{width: "85px"}} />
            <span style={{fontSize: "14px", fontWeight: "bold"}}>Version {getVersion()} </span>
          </Stack>

          <RegisterForm  onSubmit={onSubmit} onCancel={onCancel} />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
