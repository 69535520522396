import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import { Container, Stack, Typography, Card } from '@mui/material';
import { isEmpty } from "lodash";

// components
import Page from 'components/Page';
// service
import { getSite, updateSite } from "services/site.service";

import SiteForm from "./SiteForm";

const EditSiteForm = () => {
  const history = useHistory();

  const { id } = useParams();
  const { isSuccess: isSuccessSite, data: resSite } = useQuery(["site-edit", { siteId: id }], getSite);
  
  const [finishLoad, setFinishLoad] = useState(false);
  const [siteState, setSiteState] = useState({});

  useEffect(() => {
    if (isSuccessSite) {
      setSiteState(resSite.data);
      setFinishLoad(true);
    }
  }, [isSuccessSite, resSite]);

  const submitSite = useMutation(values => {
    return updateSite({values, siteId: id});
  });

  const onSubmitSite = (values) => {
    submitSite.mutate(values, {
      onSuccess: (res) => {
        if (res) {
          history.goBack();
        }
      }
    });
  }

  return (
    <Page title="Sites Management">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Edit Site
          </Typography>
        </Stack>

        <Card>
          {
            (finishLoad === true && !isEmpty(siteState)) && (
              <SiteForm 
                initialValues={siteState}
                onSubmit={onSubmitSite}
              />
            )
          }
        </Card>
      </Container>
    </Page>
  );
}

export default EditSiteForm;