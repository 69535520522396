import axiosReq from "utils/axiosConfig";
import { alertError, alertSuccess } from "./alert.service";

export const getUsers = async ({ queryKey }) => {
  try {
    const query = queryKey.length > 1 ? queryKey[1] : {};

    const res = await axiosReq.get(`/api/admin/users`, {
      params: { ...query }
    });
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const getApprovalUsers = async () => {
  try {
    const res = await axiosReq.get(`/api/admin/users/approval`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const getUser = async ({ queryKey }) => {
  const { userId } = queryKey.length > 1 ? queryKey[1] : {};
  try {
    const res = await axiosReq.get(`/api/admin/users/${userId}`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const getUserByuId = async ( userId ) => {
  // const { userId } = queryKey.length > 1 ? queryKey[1] : {};
  try {
    const res = await axiosReq.get(`/api/admin/users/${userId}`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const createUser = async ({values}) => {
  try {
    const res = await axiosReq.post(`/api/admin/users/`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const sendEmail = async (recipientId) => {
  try {
    // console.log(recipientId);
    const res = await axiosReq.post(`/api/admin/users/sendemail/${recipientId}`);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const updateUser = async ({values, userId}) => {
  try {
    const res = await axiosReq.put(`/api/admin/users/${userId}`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const deleteUser = async ({deleteId}) => {
  try {
    const res = await axiosReq.delete(`/api/admin/users/${deleteId}`);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const activateAccount = async (userId) => {
  try {
    console.log(userId);
    const res = await axiosReq.put(`/api/auth/activateaccount/${userId}`);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}