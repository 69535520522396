import axiosReq from "utils/axiosConfig";
import { alertError, alertSuccess } from "./alert.service";

export const getWorkday = async ({ queryKey }) => {
  try {
    const { organizationId } = queryKey.length > 1 ? queryKey[1] : {};
    const res = await axiosReq.get(`/api/user/workdays`, {
      params: {
        organizationId
      }
    });
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const updateWorkday = async ({values}) => {
  try {
    const res = await axiosReq.put(`/api/user/workdays`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}