import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import { TextField } from "@mui/material";

const TableWrapper = styled.div`
  .rdt_TableHead .rdt_TableCol {
    font-weight: bold;
  }

  > header {
    padding: 15px;
  }
`;

const TableList = ({
  columns,
  data,
  isFilter,
  pagination,
  ...props
}) => {
  const [filterText, setFilterText] = useState("");

  const anyKeyFilter = (item) => obj => {
    const filteredKeys = Object.keys(obj).filter(x => x !== "uId" && x !== "_id" && x !== "key");
    const values = filteredKeys.map(x => typeof obj[x] === "string" ? obj[x].toLowerCase() : "");
    const match = values.filter(el => {
      if (el.includes(item.toLowerCase())) {
        return true;
      }
    });

    if (match.length > 0) {
      return true;
    }

    return false;
  }
  const filteredData = data.filter(anyKeyFilter(filterText));

  const subHeaderComponent = useMemo(() => {
    return (
      <TextField 
        label="Search"
        variant="outlined"
        onChange={e => setFilterText(e.target.value)} 
        value={filterText} 
      />
    )
  }, [filterText]);
  
  return (
    <TableWrapper {...props}>
      <DataTable
        columns={columns}
        data={filteredData}
        pagination={pagination}
        subHeader
        subHeaderComponent={isFilter ? subHeaderComponent : ""}
        {...props}
      />
    </TableWrapper>
  );
}

TableList.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  isFilter: PropTypes.bool,
  pagination: PropTypes.bool,
};

TableList.defaultProps = {
  columns: [],
  data: [],
  isFilter: false,
  pagination: true,
};

export default TableList;
