import React, { useState, useEffect } from "react";
import { 
  Container, 
  Stack, 
  Typography,
  Box,
  Card
} from '@mui/material';
import { useMutation, useQuery, QueryClient } from "react-query";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import CorporateForm from "pages/shared/corporateForm";

import Page from 'components/Page';
import { fToInputDate } from "utils/formatTime";
import { getCorporate, updateCorporate } from "services/adminCorporate.service";

import Workday from "./Workday";

const CorporateSetting = () => {
  const queryClient = new QueryClient();
  const selectedOrg = useSelector((state) => state.userData?.orgId);
  const { isSuccess, data } = useQuery(["corporate-edit", { corpId: selectedOrg }], getCorporate);
  
  const [corporateState, setCorporateState] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      setCorporateState({
        ...data,
        dateOfIncorporation: fToInputDate(data?.dateOfIncorporation)
      });
    }
  }, [isSuccess, data]);


  const submitCorp = useMutation(values => {
    return updateCorporate({values, corpId: selectedOrg});
  });

  const onSubmitCorp = (values) => {
    submitCorp.mutate(values, {
      onSuccess: (res) => {
        if (res)
          queryClient.setQueriesData(["corporate-edit", { corpId: selectedOrg }], res)
      },
      onSettled: () => {
        queryClient.invalidateQueries(["corporate-edit", { corpId: selectedOrg }]);
      }
    });
  }

  return (
    <Page title="Corporate Setting">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Corporate Setting
          </Typography>
        </Stack>

        <Card>
          <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Card>
                {
                  !isEmpty(corporateState) && (
                    <CorporateForm 
                      initialValues={corporateState}
                      onSubmit={onSubmitCorp}
                    />
                  )
                }
              </Card>
            </Box>
          </Box>
        </Card>

        <Workday />
      </Container>
    </Page>
  );
}

export default CorporateSetting;