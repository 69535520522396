import React from "react";
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { 
  Stack, 
  TextField, 
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

const HolidayForm = ({
  initialValues,
  onSubmit
}) => {
  let HolidaySchema = Yup.object().shape({
    eventName: Yup.string().required('Name is required'),
    startDate: Yup.string().required('Start Date is required'),
    endDate: Yup.string().required('End Date is required'),
  });

  const hanldeSubmitFormik = (values) => {
    onSubmit(values);
  }

  const formik = useFormik({
    initialValues,
    validationSchema: HolidaySchema,
    enableReinitialize: true,
    onSubmit: (values) => hanldeSubmitFormik(values)
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{m: 2}}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Event Name"
              {...getFieldProps('eventName')}
              error={Boolean(touched.eventName && errors.eventName)}
              helpertext={touched.eventName && errors.eventName}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              id="startDate"
              label="Start Date"
              type="date"
              {...getFieldProps('startDate')}
              sx={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
              error={Boolean(touched.startDate && errors.startDate)}
              helperText={(touched.startDate && errors.startDate) ? errors.startDate : ""}
            />
            <TextField
              id="endDate"
              label="End Date"
              type="date"
              {...getFieldProps('endDate')}
              sx={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
              error={Boolean(touched.endDate && errors.endDate)}
              helperText={(touched.endDate && errors.endDate) ? errors.endDate : ""}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Description"
              multiline={true}
              minRows={4}
              {...getFieldProps('description')}
            />
          </Stack>
        </Stack>
        <LoadingButton
          sx={{m: 2}}
          style={{display: "block"}}
          size="large"
          type="submit"
          variant="contained"
        >
          Submit
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}

HolidayForm.propTypes = {
  initialValues: PropTypes.object,
  apps: PropTypes.object,
  isEdit: PropTypes.bool,
  onSubmit: PropTypes.func,
};

HolidayForm.defaultProps = {
  initialValues: {
    name: "",
    description: "",
    startDate: "",
    endDate: "",
  },
  onSubmit: () => {}
};

export default HolidayForm;
