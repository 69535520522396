import React from "react";
import {
  Redirect,
  Route,
} from "react-router-dom";

import DashboardLayout from 'layouts/dashboard';

const ProtectedRoute = ({ component: Component, role, ...rest }) => {
  let auth = localStorage.getItem("currentUser");
  if (auth) {
    auth = JSON.parse(auth);
  }
  
  return (
    <Route 
      {...rest}

      render={(props) => {
        if (!auth) return (
          <Redirect to="/admin/login" />
        )

        if (auth.role !== "superAdmin") return (
          <Redirect to="/admin/login" />
        )

        if (auth) return (
            <DashboardLayout role={role}>
              <Component {...props} />
            </DashboardLayout>
        )
      }}
    />
  )
}

export default ProtectedRoute;