import React from "react";
import { useSelector } from "react-redux";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { Container, Stack, Typography, Card } from '@mui/material';

// components
import Page from 'components/Page';
// services
import { createDepartment } from "services/department.service";

import DepartmentForm from "./DepartmentForm";

const CreateDepartmentForm = () => {
  const history = useHistory();
  const selectedOrg = useSelector((state) => state.userData?.orgId);

  const submitDepartment = useMutation(values => {
    return createDepartment({values: {...values, organizationId: selectedOrg}});
  });

  const onSubmitDepartment = (values) => {
    submitDepartment.mutate(values, {
      onSuccess: (res) => {
        if (res) {
          history.goBack();
        }
      }
    });
  }

  return (
    <Page title="Department">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Create Department
          </Typography>
        </Stack>

        <Card>
          <DepartmentForm 
            onSubmit={onSubmitDepartment}
          />
        </Card>
      </Container>
    </Page>
  );
}

export default CreateDepartmentForm;