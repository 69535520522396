import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TableContainer,
} from '@mui/material';
// components
import Page from 'components/Page';
import Scrollbar from 'components/Scrollbar';
import TableList from 'components/tableList';
import ModalConfirmation from 'components/modalConfirmation';
import { UserMoreMenu } from 'components/_dashboard/user';
import { fDate } from "utils/formatTime";
// services
import { getHolidays, deleteHoliday } from "services/holiday.service";

export default function Holiday() {
  const history = useHistory();
  const selectedOrg = useSelector((state) => state.userData?.orgId);

  const { isSuccess, data: resHoliday } = useQuery(["holidays", { organizationId: selectedOrg }], getHolidays);

  const [holidays, setHolidays] = useState([]);
  const [deleteId, setDeletedId] = useState("");
  const [show, setShow] = useState(false);
 
  useEffect(() => {
    if (isSuccess) {
      setHolidays(resHoliday?.data ?? []);
    }
  }, [isSuccess, resHoliday]);

  const columnHolidays = useMemo(() => {
    return [
      {
        name: "#",
        selector: (_, index) => index + 1,
        width: "80px"
      },
      {
        name: "Name",
        selector: row => row.name,
        sortable: true,
      },
      {
        name: "Start Date",
        selector: row => fDate(row.startDate),
        sortable: true,
      },
      {
        name: "End Date",
        selector: row => fDate(row.endDate),
        sortable: true,
      },
      {
        name: "Action",
        button: true,
        cell: row => (
          <UserMoreMenu 
            onEdit={() => history.push(`/holiday/edit/${row.uId}`)}
            onDelete={() => {
              setDeletedId(row.uId);
              setShow(true);
            }}
          />
        )
      }
    ]
  }, [history]);

  const sourceHolidays = useMemo(() => {
    return holidays.map((holiday, index) => (
      {
        key: index,
        uId: holiday.uId,
        _id: holiday._id,
        name: holiday.eventName,
        startDate: holiday.startDate,
        endDate: holiday.endDate
      }
    ))
  }, [holidays]);

  const handleDelete = useCallback(async () => {
    const res = await deleteHoliday({deleteId});
    if (res) {
      setHolidays(holidays.filter(x => x.uId !== deleteId));
    }

    setShow(false);
  }, [deleteId, holidays]);

  return (
    <Page title="Holiday">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Holiday
          </Typography>

          
          <Button
            variant="contained"
            component={RouterLink}
            to="/holiday/create"
            startIcon={<Icon icon={plusFill} />}
          >
            New Holiday
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <TableList
                columns={columnHolidays}
                data={sourceHolidays}
                isFilter={true}
              />
            </TableContainer>
          </Scrollbar>
        </Card>

        <ModalConfirmation 
          open={show}
          handleClose={() => setShow(false)}
          handleOk={() => handleDelete()}
        />
      </Container>
    </Page>
  );
}
