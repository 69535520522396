import React, { useState } from "react";
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { 
  Stack, 
  TextField, 
  IconButton,
} from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { LoadingButton } from '@mui/lab';
// ----------------------------------------------------------------------

const AppForm = ({
  initialValues,
  onSubmit
}) => {
  const [fileImage, setFileImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(initialValues.image ? initialValues.image : "");

  const UserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    code: Yup.string().required('Vode is required'),
  });

  const handleSubmitFormdata = (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("code", values.code);
    formData.append("order", values.order ? values.order : 0);
    formData.append("link", values.link);

    if (fileImage !== null) {
      formData.append("image", fileImage);
    }

    onSubmit(formData);
  }

  const formik = useFormik({
    initialValues,
    validationSchema: UserSchema,
    enableReinitialize: true,
    onSubmit: (values) => handleSubmitFormdata(values)
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const handleUpload = (e) => {
    if (e.target.files?.length > 0) {
      const file = e.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(file);
  
      reader.onloadend = (e) => {
        setPreviewImage(reader.result);
      }

      setFileImage(file);
    }
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{m: 2}}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <div>
              {
                previewImage !== "" && (
                  <img src={previewImage} alt="logo" />
                )
              }
            </div>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <div style={{display: "flex", alignItems: "center"}}>
              <label>Logo : </label>
              <IconButton color="primary" aria-label="upload picture" component="label">
                <input hidden accept="image/*" type="file" onChange={(e) => handleUpload(e)} />
                <PhotoCamera />
              </IconButton>
            </div>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Name"
              defaultValue={getFieldProps("name").value}
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helpertext={touched.name && errors.name}
            />

            <TextField
              fullWidth
              label="Code"
              {...getFieldProps('code')}
              error={Boolean(touched.code && errors.code)}
              helpertext={touched.code && errors.code}
            />

            <TextField
              fullWidth
              label="Order"
              type="number"
              {...getFieldProps('order')}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Link"
              defaultValue={getFieldProps("link").value}
              {...getFieldProps('link')}
            />
          </Stack>
        </Stack>
        <LoadingButton
          sx={{m: 2}}
          style={{display: "block"}}
          size="large"
          type="submit"
          variant="contained"
        >
          Submit
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}

AppForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

AppForm.defaultProps = {
  initialValues: {
    name: "",
    code: "",
  },
  onSubmit: () => {}
};

export default AppForm;
