import React from "react";
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { 
  Stack, 
  TextField, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem,
  OutlinedInput,
  Chip,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { getUniqueArr } from "utils/common";
import { fToInputDate } from "utils/formatTime";

import { LicenseWrapper } from "./styled";

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const UserForm = ({
  initialValues,
  dataOrg,
  corps,
  apps,
  isEdit,
  onSubmit
}) => {
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => hanldeSubmitFormik(values)
  });

  const { values, handleSubmit, getFieldProps, setFieldValue } = formik;

  const hanldeSubmitFormik = (values) => {
    let appControls = [];
    values.orgAccessControls.map((items) => {
      appControls = [...appControls, ...items.map(x => x.label)];
    });
    appControls = getUniqueArr(appControls);

    if (isEdit) {
      delete values.email;
      delete values.password;
    }

    onSubmit({
      ...values,
      approve: true,
      isApproval: true,
      appAccessControls: appControls
    })
  }

  const addLicense = (index, value) => {
    values.orgAccessControls[index] = value.map(x => {
      const existValue = values.orgAccessControls[index]?.find(a => a.label === x);
      const today = new Date();

      return {
        label: x,
        startDate: existValue ? existValue.startDate : fToInputDate(new Date()),
        expiredDate: existValue ? existValue.expiredDate : fToInputDate(new Date(today.setMonth(today.getMonth()+3))),
      }
    });

    setFieldValue("orgAccessControls", values.orgAccessControls);
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{m: 2}}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="First Name"
              {...getFieldProps('firstName')}
              disabled={true}
            />

            <TextField
              fullWidth
              label="Last Name"
              {...getFieldProps('lastName')}
              disabled={true}
            />

            <TextField
              fullWidth
              label="Username"
              {...getFieldProps('userName')}
              disabled={true}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Email"
              {...getFieldProps('email')}
              disabled={true}
            />
            <TextField
              fullWidth
              label="Organization Name"
              value={dataOrg.approvalOrgName}
              disabled={true}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="UEN"
              value={dataOrg.approvalOrgUEN}
              disabled={true}
            />
            <TextField
              id="incorporationDate"
              label="Date Of Incorporation"
              type="date"
              value={fToInputDate(dataOrg.dateOfIncorporation)}
              sx={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={true}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <LicenseWrapper>
              <span className="fw-bold">Assign License</span>
              <div className="mt-2">
                {
                  values.organizations.map((org, orgIdx) => {
                    const selectedOrg = corps.find(x => x.value === org);

                    return (
                      <div key={orgIdx} className="mb-2">
                        <FormControl className="mt-1" sx={{ width: "100%" }}>
                          <InputLabel>Licenses</InputLabel>
                          <Select
                            labelId={`orgAccessControls${orgIdx}`}
                            id={`orgAccessControls${orgIdx}`}
                            multiple
                            value={values.orgAccessControls?.[orgIdx] ? values.orgAccessControls?.[orgIdx]?.map(x => x.label) : []}
                            onChange={(e) => addLicense(orgIdx, e.target.value)}
                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                            renderValue={(selected) => (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                  <Chip key={value} label={apps.find(x => x.value.toLowerCase() === value.toLowerCase())?.label} />
                                ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {apps.map((app) => (
                              <MenuItem
                                key={app.label}
                                value={app.value}
                              >
                                {app.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {
                          values.orgAccessControls?.[orgIdx]?.filter(x => x.label.toLowerCase() !== "eprofile")?.map((app, appIdx) => {
                            const arrIdx = values.orgAccessControls?.[orgIdx]?.findIndex(x => x.label.toLowerCase() === app.label.toLowerCase());
                            return (
                              <div key={appIdx} className="mt-2">
                                <TextField
                                  id={`startDate${orgIdx}-${arrIdx}`}
                                  label={`${app.label} Start Date`}
                                  type="date"
                                  style={{paddingRight: "10px"}}
                                  {...getFieldProps(`orgAccessControls.${orgIdx}.${arrIdx}.startDate`)}
                                  sx={{ width: "50%" }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                                <TextField
                                  id={`expiredDate${orgIdx}-${arrIdx}`}
                                  label={`${app.label} Expired Date`}
                                  type="date"
                                  style={{paddingLeft: "10px"}}
                                  {...getFieldProps(`orgAccessControls.${orgIdx}.${arrIdx}.expiredDate`)}
                                  sx={{ width: "50%" }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  })
                }
              </div>
            </LicenseWrapper>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Comment"
              multiline={true}
              rows={3}
              {...getFieldProps('comment')}
            />
          </Stack>
        </Stack>
        <LoadingButton
          sx={{m: 2}}
          style={{display: "inline-block"}}
          size="large"
          type="submit"
          variant="contained"
          className="danger-btn"
        >
          Reject
        </LoadingButton>
        <LoadingButton
          sx={{m: 2}}
          style={{display: "inline-block"}}
          size="large"
          type="submit"
          variant="contained"
        >
          Approve
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}

UserForm.propTypes = {
  initialValues: PropTypes.object,
  apps: PropTypes.array,
  isEdit: PropTypes.bool,
  onSubmit: PropTypes.func,
};

UserForm.defaultProps = {
  initialValues: {
    firstName: "",
    lastName: "",
    userName: "",
    nationalId: "",
    organizations: [],
    appAccessControls: [],
    orgAccessControls: [],
    password: "",
    confirmPassword: "",
    email: "",
    city: "",
    postalCode: "",
    gender: "man",
    country: "SG",
    stage: "",
    language: "en",
    role: "admin",
    address: [""],
    comment: "",
  },
  apps: [],
  isEdit: false,
  onSubmit: () => {}
};

export default UserForm;
