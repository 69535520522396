import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TableContainer,
} from '@mui/material';
// components
import Page from 'components/Page';
import Scrollbar from 'components/Scrollbar';
import TableList from 'components/tableList';
import ModalConfirmation from 'components/modalConfirmation';
import { UserMoreMenu } from 'components/_dashboard/user';

import { getAlias } from "utils/common";
import { fDate } from "utils/formatTime";
// services
import { getCorporates, deleteCorporate } from "services/adminCorporate.service";

export default function Corporate() {
  const history = useHistory();
  const [deleteId, setDeletedId] = useState("");
  const [show, setShow] = useState(false);
  const { isSuccess, data } = useQuery(["corps"], getCorporates);
 
  const [corps, setCorps] = useState([]);

  useEffect(() => {
    if (isSuccess && data) {
      setCorps(data.map(item => (
        {
          ...item,
          alias: getAlias(item.alias)
        }
      )));
    }
  }, [isSuccess, data]);

  const columnCorps = useMemo(() => {
    return [
      {
        name: "#",
        selector: (_, index) => index + 1,
        width: "80px"
      },
      {
        name: "Corp Name",
        selector: row => row.corpName,
        sortable: true
      },
      {
        name: "Alias",
        selector: row => row.alias,
        sortable: true
      },
      {
        name: "Date of Incorporation",
        selector: row => row.dateOfIncorporation,
        sortable: true
      },
      {
        name: "Contact No",
        selector: row => row.contactNo,
        sortable: true
      },
      {
        name: "Action",
        button: true,
        cell: row => (
          <UserMoreMenu 
            onEdit={() => history.push(`/admin/corporate/edit/${row.uId}`)}
            onDelete={() => {
              setDeletedId(row.uId);
              setShow(true);
            }}
          />
        )
      }
    ]
  }, [history]);

  const sourceCorps = useMemo(() => {
    return corps.map((corp, index) => (
      {
        key: index,
        uId: corp.uId,
        _id: corp._id,
        corpName: corp.corpName,
        alias: corp.alias,
        dateOfIncorporation: fDate(corp.dateOfIncorporation),
        contactNo: corp.contactNo,
      }
    ))
  }, [corps]);

  const handleDelete = useCallback(async () => {
    const res = await deleteCorporate({deleteId});
    if (res) {
      setCorps(corps.filter(x => x.uId !== deleteId));
    }

    setShow(false);
  }, [deleteId, corps]);


  return (
    <Page title="Corporate">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Corporate
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/admin/corporate/create"
            startIcon={<Icon icon={plusFill} />}
          >
            New Corporate
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <TableList
                columns={columnCorps}
                data={sourceCorps}
                isFilter={true}
              />
            </TableContainer>
          </Scrollbar>
        </Card>

        <ModalConfirmation 
          open={show}
          handleClose={() => setShow(false)}
          handleOk={() => handleDelete()}
        />
      </Container>
    </Page>
  );
}
