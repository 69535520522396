import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import styled from "styled-components";

import { activateAccount } from "services/adminUser.service";

const Wrapper = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    > div {
        text-align: center;

        .MuiCircularProgress-root {
            width: 100px !important;
            height: 100px !important;
        }

        p {
            font-size: 22px;
            width: 300px;
            margin-top: 25px;
        }
    }
`;

const Activate = () =>{
    const { id } = useParams();
    
    useEffect(() =>{
        activateAccount(id);
    });

    return (
        <Wrapper>
            <div>
                <CircularProgress />
                <p>Please wait a moment while we activate your account.</p>
            </div>
        </Wrapper>
    )
}

export default Activate;