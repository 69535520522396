import React, { useState, useEffect } from "react";
import { useMutation, useQuery, QueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { Container, Stack, Typography, Card } from '@mui/material';
import { isEmpty } from "lodash";

// components
import Page from 'components/Page';
// service
import { getApp, updateApp } from "services/adminApp.service";

import AppForm from "./AppForm";

const EditAppForm = () => {
  const queryClient = new QueryClient();
  const { id } = useParams();
  const { isSuccess, data } = useQuery(["app-edit", { corpId: id }], getApp);

  const [appState, setAppState] = useState({});

  useEffect(() => {
    if (isSuccess) {
      setAppState(data);
    }
  }, [isSuccess, data]);

  const submitCorp = useMutation(values => {
    return updateApp({values, corpId: id});
  });

  const onSubmitCorp = (values, actions) => {
    submitCorp.mutate(values, {
      onSuccess: (res) => {
        if (res) {
          queryClient.setQueriesData(["app-edit", { corpId: id }], res);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(["app-edit", { corpId: id }]);
      }
    });
  }

  return (
    <Page title="App">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Edit App
          </Typography>
        </Stack>

        <Card>
          {
            (isSuccess === true && !isEmpty(appState)) && (
              <AppForm 
                initialValues={appState}
                onSubmit={onSubmitCorp}
              />
            )
          }
        </Card>
      </Container>
    </Page>
  );
}

export default EditAppForm;