import axiosReq from "utils/axiosConfig";
import { alertError, alertSuccess } from "./alert.service";

export const getApps = async ({ queryKey }) => {
  try {
    const { appAccessControls } = queryKey.length > 1 ? queryKey[1] : {};
    const res = await axiosReq.get(`/api/user/apps`, {
      params: {
        code: appAccessControls
      }
    });
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const getApp = async ({ queryKey }) => {
  const { corpId } = queryKey.length > 1 ? queryKey[1] : {};
  try {
    const res = await axiosReq.get(`/api/user/apps/${corpId}`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}