import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { AppDrawer } from "@bsilobook/component";
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import styledComponents from "styled-components";
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
// components
import AppIcon from "assets/app.svg";
import { MHidden } from 'components/@material-extend';

import { getApps } from "services/userApp.service";
import AccountPopover from './AccountPopover';
// import LanguagePopover from './LanguagePopover';

const AppDrawerWrapper = styledComponents.div`
  div p {
    margin: 0px;
    color: #212B36;
    font-size: 14px;
  }
`;

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ role, onOpenSidebar }) {
  const currentUser = useSelector((state) => state.userData?.currentUser);
  const [apps, setApps] = useState([]);

  useEffect(() => {
    const queryKey = [];
    getApps({queryKey})
    .then((res) => {
      if (res) {
        const availableApps = res.filter(x => currentUser?.appAccessControls?.filter(f => f == x.code).length > 0);
        const mapApps = availableApps?.map((x) => (
          {
            code: x.code,
            label: x.name,
            link: x.link,
            image: x.image ? x.image : AppIcon
          }
        ))
        setApps(mapApps);
      }
    })
  }, []);

  return (
    <RootStyle>
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/* <LanguagePopover /> */}
          {/* <NotificationsPopover /> */}
          {
            currentUser?.role !== "superAdmin" && (
              <AppDrawerWrapper>
                <AppDrawer 
                  apps={apps}
                  token={currentUser?.token}
                  orgId={currentUser?.encryptOrgId}
                  width="40px"
                  height="40px"
                  popupRight="20px"
                  popupTop="70px"
                  bgColor="#ddd"
                />
              </AppDrawerWrapper>
            )
          }
          <AccountPopover role={role} />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
