import axiosReq from "utils/axiosConfig";
import { alertError, alertSuccess } from "./alert.service";

export const getLicenses = async ({ queryKey }) => {
  try {
    const query = queryKey.length > 1 ? queryKey[1] : {};

    const res = await axiosReq.get(`/api/user/licenses`, {
      params: { ...query }
    });
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const assignLicense = async ({values}) => {
  try {
    console.log(values)
    const res = await axiosReq.put(`/api/user/licenses/multi/assign`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}