import React, { useMemo, useState, useEffect } from "react";
import { useQuery } from "react-query";
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
// material
import { 
  Stack, 
  TextField, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem,
  FormGroup,
  FormControlLabel,
  Radio,
  RadioGroup,
  OutlinedInput,
  Chip,
  Box,
  InputAdornment,
  IconButton
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';
import plusCircle from '@iconify/icons-eva/plus-circle-outline';
import minusCircle from '@iconify/icons-eva/minus-circle-outline';
import cameraOutline from '@iconify/icons-eva/camera-outline';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import codes from "iso-language-codes"

import { getUniqueArr } from "utils/common";
import { fToInputDate } from "utils/formatTime";
import { getList } from "services/csc.service";

import { LicenseWrapper, UploadWrapper } from "./styled";

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const UserForm = ({
  initialValues,
  corps,
  apps,
  isEdit,
  onSubmit
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [fileImage, setFileImage] = useState(null);
  const [previewImage, setPreviewImage] = useState((initialValues.profile && initialValues.profile !== "") ? initialValues.profile : null);

  let UserSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    userName: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string().required('Confirm Password is required'),
    email: Yup.string().required('Email is required'),
  });

  if (isEdit) {
    UserSchema = Yup.object().shape({
      firstName: Yup.string().required('First Name is required'),
      userName: Yup.string().required('Username is required'),
    });
  }

  const formik = useFormik({
    initialValues : {
      ...initialValues,
      orgAccessControls: isEdit ? initialValues.orgAccessControls : [],
    },
    validationSchema: UserSchema,
    enableReinitialize: true,
    onSubmit: (values) => hanldeSubmitFormik(values)
  });

  const { values, errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

  const { isSuccess: isSuccessCountry, data: resCountry } = useQuery(["coutries"], getList);
  const { isSuccess: isSuccessState, data: resState } = useQuery(
    ["states", { country_code: values.country }],
    getList,
    { enabled: values.country !== "" }
  );
  const { isSuccess: isSuccessCity, data: resCity } = useQuery(
    ["cities", { country_code: values.country, state_code: values.state }],
    getList,
    { enabled: values.state !== "" }
  );

  useEffect(() => {
    setPreviewImage((initialValues.profile && initialValues.profile !== "") ? initialValues.profile : null);
  }, [initialValues]);

  useEffect(() => {
    if (isSuccessCountry) {
      setCountries(resCountry?.data ?? []);
    }
  }, [isSuccessCountry, resCountry]);

  useEffect(() => {
    if (isSuccessState) {
      setStates(resState?.data ?? []);
    }
  }, [isSuccessState, resState]);

  useEffect(() => {
    if (isSuccessCity) {
      setCities(resCity?.data ?? []);
    }
  }, [isSuccessCity, resCity]);

  const hanldeSubmitFormik = (values) => {
    let appControls = [];
    values.orgAccessControls.map((items) => {
      appControls = [...appControls, ...items.map(x => x.label)];
    });
    appControls = getUniqueArr(appControls);

    if (isEdit) {
      // delete values.email;
      delete values.password;
    }

    const objValues = {
      ...values,
      appAccessControls: appControls
    }

    const formData = new FormData();
    Object.keys(objValues).filter(x => x !== "profile").map((field) => {
      if (
        field === "organizations" || 
        field === "appAccessControls" || 
        field === "orgAccessControls" || 
        field === "address"
      ) {
        formData.append(field, JSON.stringify(objValues[field]));
      } else {
        formData.append(field, objValues[field]);
      }
    });

    if (fileImage) {
      formData.append("profile", fileImage);
    }

    onSubmit(formData);
  }

  const optionLang = useMemo(() => {
    return codes.map((code) => (
      {
        name: code.name,
        value: code.iso639_1
      }
    ))
  }, []);

  const addAddress = (arrayHelpers) => {
    arrayHelpers.insert(getFieldProps("address").value.length, "");
  }

  const addLicense = (index, value) => {
    const updatedOrgControls = [...values.orgAccessControls];
    updatedOrgControls[index] = value.map(x => {
      const existValue = updatedOrgControls[index]?.find(a => a.label === x);
      const today = new Date();

      return {
        label: x,
        startDate: existValue ? existValue.startDate : fToInputDate(new Date()),
        expiredDate: existValue ? existValue.expiredDate : fToInputDate(new Date(today.setMonth(today.getMonth()+3))),
      }
    });

    setFieldValue("orgAccessControls", updatedOrgControls);
  }

  const onUploadProfile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = (e) => {
      setPreviewImage(reader.result);
    }

    setFileImage(e.target.files[0])
	}

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{m: 2}}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} style={{margin: "auto", marginBottom: "20px"}}>
            <UploadWrapper onClick={() => document.getElementById("inputProfile").click()}>
              <input hidden={true} id="inputProfile" type="file" onChange={(e) => onUploadProfile(e)} />
              <div>
                <Icon icon={cameraOutline} />
              </div>
              {
                previewImage && (
                  <img className="profile-pic" src={previewImage} alt="profile" />
                )
              }
            </UploadWrapper>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="First Name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={(touched.firstName && errors.firstName) ? errors.firstName : ""}
            />

            <TextField
              fullWidth
              label="Last Name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />

            <TextField
              fullWidth
              label="Username"
              {...getFieldProps('userName')}
              error={Boolean(touched.userName && errors.userName)}
              helperText={(touched.userName && errors.userName) ? errors.userName : ""}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              id="dateOfBirth"
              label="Date Of Birth"
              type="date"
              {...getFieldProps('dateOfBirth')}
              sx={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              fullWidth
              label="National ID"
              {...getFieldProps('nationalId')}
              error={Boolean(touched.nationalId && errors.nationalId)}
              helperText={touched.nationalId && errors.nationalId}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            {/* <FormControl sx={{ width: "100%" }}>
              <InputLabel>App Access Controls</InputLabel>
              <Select
                labelId="appAccessControls"
                id="appAccessControls"
                multiple
                {...getFieldProps('appAccessControls')}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={apps.find(x => x.value === value)?.label} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {apps.map((app) => (
                  <MenuItem
                    key={app.label}
                    value={app.value}
                  >
                    {app.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}

            <FormControl sx={{ width: "100%" }}>
              <InputLabel>Organizations</InputLabel>
              <Select
                labelId="organizations"
                id="organizations"
                multiple
                // {...getFieldProps('organizations')}
                value={values.organizations}
                onChange={e => {
                  const defaultValueOrg = [];
                  e.target.value?.map((orgId, index) => {
                    const today = new Date();
                    if (values.orgAccessControls[index]) {
                      defaultValueOrg.push([...values.orgAccessControls[index]])
                    }
                    defaultValueOrg.push([
                      {
                        label: "Eprofile",
                        startDate: new Date(),
                        expiredDate: new Date(today.setMonth(today.getMonth()+3))
                      }
                    ])
                  })

                  setFieldValue("organizations", e.target.value);
                  setFieldValue("orgAccessControls", defaultValueOrg);
                }}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={corps.find(x => x.value === value)?.label} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {corps.map((corp) => (
                  <MenuItem
                    key={corp.label}
                    value={corp.value}
                  >
                    {corp.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Email"
              // disabled={isEdit}
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={(touched.email && errors.email) ? errors.email : ""}
            />

            <TextField
              fullWidth
              label="Contact No"
              {...getFieldProps('contactNo')}
              error={Boolean(touched.contactNo && errors.contactNo)}
              helperText={touched.contactNo && errors.contactNo}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Password"
              type={showPassword ? "text" : "password"}
              disabled={isEdit}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={e => e.preventDefault()}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              {...getFieldProps('password')}
              error={Boolean(touched.password && errors.password)}
              helperText={(touched.password && errors.password) ? errors.password : ""}
            />

            <TextField
              fullWidth
              label="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              disabled={isEdit}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      onMouseDown={e => e.preventDefault()}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              {...getFieldProps('confirmPassword')}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              helperText={(touched.confirmPassword && errors.confirmPassword) ? errors.confirmPassword : ""}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControl sx={{width: "50%"}}>
              <InputLabel>Country</InputLabel>
              <Select
                label="Country"
                {...getFieldProps('country')}
              >
                {
                  countries.map((opt, index) => (
                    <MenuItem key={index} value={opt.country_code}>{opt.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <FormControl sx={{width: "50%"}}>
              <InputLabel>State</InputLabel>
              <Select
                label="State"
                {...getFieldProps('state')}
              >
                {
                  states.map((opt, index) => (
                    <MenuItem key={index} value={opt.state_code}>{opt.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControl sx={{width: "100%"}}>
              <InputLabel>City</InputLabel>
              <Select
                label="City"
                {...getFieldProps('city')}
              >
                {
                  cities.map((opt, index) => (
                    <MenuItem key={index} value={opt.name}>{opt.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>

            <TextField
              fullWidth
              label="Postal Code"
              {...getFieldProps('postalCode')}
              error={Boolean(touched.postalCode && errors.postalCode)}
              helperText={touched.postalCode && errors.postalCode}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FieldArray
              name="address"
              render={arrayHelpers => (
                <div style={{width: "100%"}}>
                  <div style={{marginBottom: "10px", cursor: "pointer"}}>
                    <div 
                      style={{width: "200px", fontWeight: "bold"}} 
                      className="cs-pointer" 
                      onClick={() => addAddress(arrayHelpers)}
                    >
                      <Icon icon={plusCircle} style={{fontSize: "20px", position: "relative", top: "4px", marginRight: "10px"}} />
                      <span>Add Address</span>
                    </div>
                  </div>

                  {
                    (getFieldProps("address").value.length > 0) &&
                      getFieldProps("address").value.map((value, index) => (
                        <React.Fragment key={index}>
                          <TextField
                            key={index}
                            sx={{mb: 2, width: "90%"}}
                            label={`Address ${index + 1}`}
                            {...getFieldProps(`address.${index}`)}
                            error={Boolean(touched.address && errors.address)}
                            helperText={touched.address && errors.address}
                          />
                          <Icon 
                            icon={minusCircle} 
                            style={{fontSize: "20px", position: "relative", top: "20px", left: "10px", cursor: "pointer"}} 
                            onClick={() => arrayHelpers.remove(index)}
                          />
                        </React.Fragment>
                      ))
                    }
                </div>
              )}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControl sx={{width: "50%"}}>
              <InputLabel>Language</InputLabel>
              <Select
                label="Language"
                {...getFieldProps('language')}
                error={Boolean(touched.language && errors.language)}
                helperText={touched.language && errors.language}
              >
                {
                  optionLang.map((opt, index) => (
                    <MenuItem key={index} value={opt.value}>{opt.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormGroup>
              <InputLabel>Gender</InputLabel>
              <RadioGroup
                name="gender"
                {...getFieldProps('gender')}
              >
                <FormControlLabel value="man" control={<Radio defaultChecked />} label="Man" />
                <FormControlLabel value="woman" control={<Radio />} label="Woman" />
              </RadioGroup>
            </FormGroup>
            <FormGroup>
              <InputLabel>Role</InputLabel>
              <RadioGroup
                name="role"
                {...getFieldProps('role')}
              >
                <FormControlLabel value="admin" control={<Radio defaultChecked />} label="Admin" />
                <FormControlLabel value="user" control={<Radio />} label="User" />
              </RadioGroup>
            </FormGroup>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <LicenseWrapper>
              <span className="fw-bold">Assign License</span>
              <div className="mt-2">
                {
                  values.organizations.map((org, orgIdx) => {
                    const selectedOrg = corps.find(x => x.value === org);

                    return (
                      <div key={orgIdx} className="mb-2">
                        <span>{selectedOrg?.label ?? "-"}</span>
                        <FormControl className="mt-1" sx={{ width: "100%" }}>
                          <InputLabel>Licenses</InputLabel>
                          <Select
                            labelId={`orgAccessControls${orgIdx}`}
                            id={`orgAccessControls${orgIdx}`}
                            multiple
                            value={values.orgAccessControls?.[orgIdx] ? values.orgAccessControls?.[orgIdx]?.map(x => x.label) : []}
                            onChange={(e) => addLicense(orgIdx, e.target.value)}
                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                            renderValue={(selected) => (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                  <Chip key={value} label={apps.find(x => x.value.toLowerCase() === value.toLowerCase())?.label} />
                                ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {apps.map((app) => (
                              <MenuItem
                                key={app.label}
                                value={app.value}
                              >
                                {app.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {
                          values.orgAccessControls?.[orgIdx]?.filter(x => x.label.toLowerCase() !== "eprofile")?.map((app, appIdx) => {
                            const arrIdx = values.orgAccessControls?.[orgIdx]?.findIndex(x => x.label.toLowerCase() === app.label.toLowerCase());
                            return (
                              <div key={appIdx} className="mt-2">
                                <TextField
                                  id={`startDate${orgIdx}-${arrIdx}`}
                                  label={`${app.label} Start Date`}
                                  type="date"
                                  style={{paddingRight: "10px"}}
                                  {...getFieldProps(`orgAccessControls.${orgIdx}.${arrIdx}.startDate`)}
                                  sx={{ width: "50%" }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                                <TextField
                                  id={`expiredDate${orgIdx}-${arrIdx}`}
                                  label={`${app.label} Expired Date`}
                                  type="date"
                                  style={{paddingLeft: "10px"}}
                                  {...getFieldProps(`orgAccessControls.${orgIdx}.${arrIdx}.expiredDate`)}
                                  sx={{ width: "50%" }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  })
                }
              </div>
            </LicenseWrapper>
          </Stack>
        </Stack>
        <LoadingButton
          sx={{m: 2}}
          style={{display: "block"}}
          size="large"
          type="submit"
          variant="contained"
        >
          Submit
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}

UserForm.propTypes = {
  initialValues: PropTypes.object,
  apps: PropTypes.array,
  isEdit: PropTypes.bool,
  onSubmit: PropTypes.func,
};

UserForm.defaultProps = {
  initialValues: {
    firstName: "",
    lastName: "",
    userName: "",
    nationalId: "",
    organizations: [],
    appAccessControls: [],
    orgAccessControls: [],
    password: "",
    confirmPassword: "",
    email: "",
    city: "",
    postalCode: "",
    gender: "man",
    country: "SG",
    stage: "",
    language: "en",
    role: "admin",
    address: [""],
  },
  apps: [],
  isEdit: false,
  onSubmit: () => {}
};

export default UserForm;
