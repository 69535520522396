import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import downloadOutline from "@iconify/icons-eva/cloud-download-outline";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TableContainer,
  Select,
  MenuItem,
} from "@mui/material";
// components
import Page from "components/Page";
import Scrollbar from "components/Scrollbar";
import TableList from "components/tableList";
import ModalConfirmation from "components/modalConfirmation";
import { UserMoreMenu } from "components/_dashboard/user";
import Label from "components/Label";
// utils
import { downloadDoc } from "utils/common";
// services
import { getUsers, deleteUser, exportUser, sendEmail } from "services/user.service";

export default function User() {
  const history = useHistory();
  const currentUser = useSelector((state) => state.userData?.currentUser);
  const selectedOrg = useSelector((state) => state.userData?.orgId);

  const { isSuccess, data } = useQuery(["users", { orgIds: [selectedOrg] }], getUsers);

  const [users, setUsers] = useState([]);
  const [deleteId, setDeletedId] = useState("");
  const [show, setShow] = useState(false);
 
  useEffect(() => {
    if (isSuccess) {
      setUsers(data.filter(x => x._id !== currentUser?._id));
    }
  }, [isSuccess, data]);

  const columnUsers = useMemo(() => {
    return [
      {
        name: "#",
        selector: (row, index) => index + 1,
        width: "80px"
      },
      {
        name: "First Name",
        selector: row => row.firstName,
        sortable: true,
      },
      {
        name: "Last Name",
        selector: row => row.lastName,
        sortable: true
      },
      {
        name: "User Name",
        selector: row => row.userName,
        sortable: true
      },
      {
        name: "Email",
        selector: row => row.email,
      },
      {
        name: "National Id",
        selector: row => row.nationalId,
      },
      {
        name: "Contact No",
        selector: row => row.contactNo,
      },
      {
        name: "Status",
        selector: row => row.approve,
        cell: row => {
          return (
            <Label
              variant="ghost"
              color={row.approve ? "success" : "error"}
            >
              {row.approve ? "Approved" : "Rejected"}
            </Label>
          )
        }
      },
      {
        name: "Action",
        button: true,
        cell: row => (
          <UserMoreMenu 
            onEdit={() => history.push(`/user/edit/${row.uId}`)}
            onDelete={() => {
              setDeletedId(row.uId);
              setShow(true);              
            }}
            onSendEmail={() => {
              sendTo(row.uId);
            }}
          />
        )
      }
    ]
  }, [history]);

  const sourceUsers = useMemo(() => {
    return users.map((user, index) => (
      {
        key: index,
        uId: user.uId,
        _id: user._id,
        firstName: user.firstName,
        lastName: user.lastName,
        userName: user.userName,
        email: user.email?.length > 0 ? user.email[0] : "",
        nationalId: user.nationalId,
        contactNo: user.contactNo,
        approve: user.approve ? "Approved" : "Rejected",
      }
    ))
  }, [users]);

  const handleDelete = useCallback(async () => {
    const res = await deleteUser({deleteId});
    if (res) {
      setUsers(users.filter(x => x.uId !== deleteId));
    }

    setShow(false);
  }, [deleteId, users]);

  const sendTo = async (userId) =>{
    await sendEmail(userId);
  }

  const handleExportCsv = () => {
    exportUser(selectedOrg)
    .then((res) => {
      if (res) {
        const blob = new Blob([res], { type: 'text/csv' });
        downloadDoc(blob, "users.csv");
      }
    })
  }

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            User
          </Typography>

          <div>
            <Button
              variant="contained"
              type="button"
              style={{marginRight: "20px"}}
              onClick={() => handleExportCsv()}
              startIcon={<Icon icon={downloadOutline} />}
            >
              Export
            </Button>
            <Button
              variant="contained"
              component={RouterLink}
              to="/user/create"
              startIcon={<Icon icon={plusFill} />}
            >
              New User
            </Button>
          </div>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <TableList
                columns={columnUsers}
                data={sourceUsers}
                isFilter={true}
              />
            </TableContainer>
          </Scrollbar>
        </Card>

        <ModalConfirmation 
          open={show}
          handleClose={() => setShow(false)}
          handleOk={() => handleDelete()}
        />
      </Container>
    </Page>
  );
}
