import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Stack, Container, Typography, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Page from 'components/Page';
import { forgetPassword } from "services/auth.service";

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export default function ForgetPassword() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState(null);

  const onSubmit = () => {
    let pass = true;
    if (email === "") {
      pass = false;
      setErrorEmail("Email is required");
    }
    if (!email.match(validRegex)) {
      pass = false;
      setErrorEmail("Invalid email address");
    }

    if (pass) {
      setErrorEmail(null);
      forgetPassword({
        email: email,
        link: `${process.env.REACT_APP_URL}/verifycode`
      }).then((res) => {
        if (res.status === 200) {
          localStorage.setItem("resetToken", res.data?.data.token);
          history.push("/verifycode");
        }
      })
    }
  }

  return (
    <RootStyle title="Forgot Password">
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }} align={"center"}>
            <Typography variant="h4" gutterBottom>
              Forgot Password
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Input your email below.</Typography>
          </Stack>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={errorEmail !== null}
              helperText={errorEmail !== null ? errorEmail : ""}
            />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={() => onSubmit()}
            >
              Send
            </LoadingButton>
          </Stack>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
