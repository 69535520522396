import { Icon } from '@iconify/react';
// import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import personDoneFill from '@iconify/icons-eva/person-done-fill';
import monitorFill from '@iconify/icons-eva/monitor-fill';
import gridFill from '@iconify/icons-eva/grid-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/admin',
  //   icon: getIcon(pieChart2Fill)
  // },
  {
    title: 'user',
    path: '/admin/user',
    icon: getIcon(peopleFill)
  },
  {
    title: 'Approval User',
    path: '/admin/approval',
    icon: getIcon(personDoneFill)
  },
  {
    title: 'corporate',
    path: '/admin/corporate',
    icon: getIcon(monitorFill)
  },
  {
    title: 'app',
    path: '/admin/app',
    icon: getIcon(gridFill)
  },
];

export default sidebarConfig;
