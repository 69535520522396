import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Stack, Container, Typography, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Page from 'components/Page';
import { verificationCode } from "services/auth.service";

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

export default function VerifyCode() {
  const history = useHistory();
  const [code, setCode] = useState("");

  const onSubmit = () => {
    verificationCode({
      pinCode: code,
      authorization: localStorage.getItem("resetToken")
    }).then((res) => {
      console.log(res);
      if (res.status === 200) {
        history.push("/resetpassword");
      }
    })
  }

  return (
    <RootStyle title="Verify Code">
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }} align={"center"}>
            <Typography variant="h4" gutterBottom>
              Verify Code
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Input your code below.</Typography>
          </Stack>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="username"
              type="input"
              label="Verify Code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={() => onSubmit()}
            >
              Verify Code
            </LoadingButton>
          </Stack>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
