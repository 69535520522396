import React, { useState } from "react";
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider } from 'formik';
import { 
  Stack, 
  TextField, 
  InputAdornment,
  IconButton
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { getUniqueArr } from "utils/common";

const RegisterForm = ({
  initialValues,
  onSubmit,
  onCancel
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  let UserSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    userName: Yup.string().required('Username is required'),
    corpName: Yup.string().required('Organization is required'),
    uen: Yup.string().required('UEN is required'),
    dateOfIncorporation: Yup.string().required('Date of Incorporation is required'),
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string().required('Confirm Password is required'),
    email: Yup.string().required('Email is required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: UserSchema,
    enableReinitialize: true,
    onSubmit: (values) => hanldeSubmitFormik(values)
  });

  const { values, errors, touched, handleSubmit, getFieldProps } = formik;

  const hanldeSubmitFormik = (values) => {
    onSubmit({...values});
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{m: 2}}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="First Name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={(touched.firstName && errors.firstName) ? errors.firstName : ""}
            />

            <TextField
              fullWidth
              label="Last Name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Username"
              {...getFieldProps('userName')}
              error={Boolean(touched.userName && errors.userName)}
              helperText={(touched.userName && errors.userName) ? errors.userName : ""}
            />

            <TextField
              fullWidth
              label="Email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={(touched.email && errors.email) ? errors.email : ""}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Organization Name"
              {...getFieldProps('corpName')}
              error={Boolean(touched.corpName && errors.corpName)}
              helperText={touched.corpName && errors.corpName}
            />
            <TextField
              fullWidth
              label="UEN"
              {...getFieldProps('uen')}
              error={Boolean(touched.uen && errors.uen)}
              helperText={touched.uen && errors.uen}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              id="dateOfIncorporation"
              label="Date Of Incorporation"
              type="date"
              {...getFieldProps('dateOfIncorporation')}
              sx={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
              error={Boolean(touched.dateOfIncorporation && errors.dateOfIncorporation)}
              helperText={touched.dateOfIncorporation && errors.dateOfIncorporation}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={e => e.preventDefault()}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              {...getFieldProps('password')}
              error={Boolean(touched.password && errors.password)}
              helperText={(touched.password && errors.password) ? errors.password : ""}
            />

            <TextField
              fullWidth
              label="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      onMouseDown={e => e.preventDefault()}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              {...getFieldProps('confirmPassword')}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              helperText={(touched.confirmPassword && errors.confirmPassword) ? errors.confirmPassword : ""}
            />
          </Stack>
        </Stack>
        <div style={{marginTop: 50}}>
          <div style={{display: "inline-block", width: "50%", paddingLeft: 15, paddingRight: 15}}>
            <LoadingButton
              sx={{width: "100%"}}
              size="large"
              type="button"
              variant="outlined"
              onClick={() => onCancel()}
            >
              Cancel
            </LoadingButton>
          </div>
          <div style={{display: "inline-block", width: "50%", paddingLeft: 15, paddingRight: 15}}>
            <LoadingButton
              sx={{width: "100%"}}
              size="large"
              type="submit"
              variant="contained"
            >
              Register
            </LoadingButton>
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
}

RegisterForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

RegisterForm.defaultProps = {
  initialValues: {
    firstName: "",
    lastName: "",
    userName: "",
    password: "",
    confirmPassword: "",
    email: "",
    corpName: "",
    uen: ""
  },
  onSubmit: () => {}
};

export default RegisterForm;
