import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Login from 'pages/admin/login';
// import PageDashboard from 'pages/admin/dashboard';
import PageUser from 'pages/admin/user';
import PageUserCreate from 'pages/admin/user/Create';
import PageUserEdit from 'pages/admin/user/Edit';
import PageApprovalUser from 'pages/admin/approvalUser';
import PageApprovalUserEdit from 'pages/admin/approvalUser/Edit';
import PageCorp from 'pages/admin/corporate';
import PageCorpCreate from 'pages/admin/corporate/Create';
import PageCorpEdit from 'pages/admin/corporate/Edit';
import PageApp from 'pages/admin/app';
import PageAppCreate from 'pages/admin/app/Create';
import PageAppEdit from 'pages/admin/app/Edit';

import ProtectedRoute from "./protected.route";

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/admin/login" component={Login} />

        <ProtectedRoute exact path="/admin" role="superAdmin" component={PageUser} />
        <ProtectedRoute exact path="/admin/user" role="superAdmin" component={PageUser} />
        <ProtectedRoute exact path="/admin/user/create" role="superAdmin" component={PageUserCreate} />
        <ProtectedRoute exact path="/admin/user/edit/:id" role="superAdmin" component={PageUserEdit} />
        <ProtectedRoute exact path="/admin/approval" role="superAdmin" component={PageApprovalUser} />
        <ProtectedRoute exact path="/admin/approval/edit/:id" role="superAdmin" component={PageApprovalUserEdit} />
        <ProtectedRoute exact path="/admin/corporate" role="superAdmin" component={PageCorp} />
        <ProtectedRoute exact path="/admin/corporate/create" role="superAdmin" component={PageCorpCreate} />
        <ProtectedRoute exact path="/admin/corporate/edit/:id" role="superAdmin" component={PageCorpEdit} />
        <ProtectedRoute exact path="/admin/app" role="superAdmin" component={PageApp} />
        <ProtectedRoute exact path="/admin/app/create" role="superAdmin" component={PageAppCreate} />
        <ProtectedRoute exact path="/admin/app/edit/:id" role="superAdmin" component={PageAppEdit} />
      </Switch>
    </Router>
  );
}

export default AppRouter;