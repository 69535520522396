import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { 
  Stack, 
  Container, 
  Typography, 
  TextField,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';

import Page from 'components/Page';
import { resetPassword } from "services/auth.service";

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

export default function ResetPassword() {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState(null);
  const [errroConfirm, setErrorConfirm] = useState(null);

  const onSubmit = () => {
    let pass = true;
    if (password === "") {
      pass = false;
      setErrorPassword("Password is required");
    }
    if (password !== confirmPassword) {
      pass = false;
      setErrorConfirm("Password not match");
    }

    if (pass) {
      resetPassword({
        password: password,
        authorization: localStorage.getItem("resetToken")
      }).then((res) => {
        if (res.status === 200) {
          history.push("/");
        }
      })
    }
  }

  return (
    <RootStyle title="Reset Password">
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }} align={"center"}>
            <Typography variant="h4" gutterBottom>
              Reset Password
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Reset your password below.</Typography>
          </Stack>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              onChange={e => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={errorPassword !== null}
              helperText={errorPassword !== null ? errorPassword : ""}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showConfirmPassword ? 'text' : 'password'}
              label="Confirm Password"
              onChange={e => setConfirmPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                      <Icon icon={showConfirmPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={errroConfirm !== null}
              helperText={errroConfirm !== null ? errroConfirm : ""}
            />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={() => onSubmit()}
            >
              Submit
            </LoadingButton>
          </Stack>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
