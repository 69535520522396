import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import { Container, Stack, Typography, Card } from '@mui/material';
import { isEmpty } from "lodash";

// components
import Page from 'components/Page';
import { fToInputDate } from "utils/formatTime";
// service
import { getHoliday, updateHoliday } from "services/holiday.service";

import HolidayForm from "./HolidayForm";

const EditHolidayForm = () => {
  const history = useHistory();

  const { id } = useParams();
  const { isSuccess: isSuccessHoliday, data: resHoliday } = useQuery(["holiday-edit", { holidayId: id }], getHoliday);
  
  const [finishLoad, setFinishLoad] = useState(false);
  const [holidayState, setHolidayState] = useState({});

  useEffect(() => {
    if (isSuccessHoliday) {
      setHolidayState({
        ...resHoliday.data,
        startDate: fToInputDate(resHoliday.data?.startDate),
        endDate: fToInputDate(resHoliday.data?.endDate),
      });
      setFinishLoad(true);
    }
  }, [isSuccessHoliday, resHoliday]);

  const submitHoliday = useMutation(values => {
    return updateHoliday({values, holidayId: id});
  });

  const onSubmitHoliday = (values) => {
    submitHoliday.mutate(values, {
      onSuccess: (res) => {
        if (res) {
          history.goBack();
        }
      }
    });
  }

  return (
    <Page title="Holiday">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Edit Holiday
          </Typography>
        </Stack>

        <Card>
          {
            (finishLoad === true && !isEmpty(holidayState)) && (
              <HolidayForm 
                initialValues={holidayState}
                onSubmit={onSubmitHoliday}
              />
            )
          }
        </Card>
      </Container>
    </Page>
  );
}

export default EditHolidayForm;