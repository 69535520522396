import axiosReq from "utils/axiosConfig";
import { alertError, alertSuccess } from "./alert.service";

export const getList = async ({ queryKey }) => {
  try {
  const { country_code, state_code } = queryKey.length > 1 ? queryKey[1] : {};
    const res = await axiosReq.get(`/api/csc`, {
      params: {
        country_code,
        state_code
      }
    });
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}