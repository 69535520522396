import React from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { Container, Stack, Typography, Card } from '@mui/material';

// components
import Page from 'components/Page';
// services
import { createApp } from "services/adminApp.service";

import AppForm from "./AppForm";

const CreateAppForm = () => {
  const history = useHistory();
  
  const submitApp = useMutation(values => {
    return createApp({values});
  });

  const onSubmitApp = (values) => {
    submitApp.mutate(values, {
      onSuccess: (res) => {
        if (res)
          history.goBack();
      }
    });
  }

  return (
    <Page title="App">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Create App
          </Typography>
        </Stack>

        <Card>
          <AppForm 
            onSubmit={onSubmitApp}
          />
        </Card>
      </Container>
    </Page>
  );
}

export default CreateAppForm;