import axiosReq from "utils/axiosConfig";
import { alertError, alertSuccess } from "./alert.service";

export const getHolidays = async ({ queryKey }) => {
  try {
  const { organizationId } = queryKey.length > 1 ? queryKey[1] : {};
    const res = await axiosReq.get(`/api/user/holidays`, {
      params: {
        organizationId
      }
    });
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const getHoliday = async ({ queryKey }) => {
  const { holidayId } = queryKey.length > 1 ? queryKey[1] : {};
  try {
    const res = await axiosReq.get(`/api/user/holidays/${holidayId}`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const createHoliday = async ({values}) => {
  try {
    const res = await axiosReq.post(`/api/user/holidays/`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const updateHoliday = async ({values, holidayId}) => {
  try {
    const res = await axiosReq.put(`/api/user/holidays/${holidayId}`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const deleteHoliday = async ({deleteId}) => {
  try {
    const res = await axiosReq.delete(`/api/user/holidays/${deleteId}`);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}