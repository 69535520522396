import axiosReq from "utils/axiosConfig";
import { alertError, alertSuccess } from "./alert.service";

export const getSites = async ({ queryKey }) => {
  try {
  const { organizationId } = queryKey.length > 1 ? queryKey[1] : {};
    const res = await axiosReq.get(`/api/user/sites`, {
      params: {
        organizationId
      }
    });
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const getSite = async ({ queryKey }) => {
  const { siteId } = queryKey.length > 1 ? queryKey[1] : {};
  try {
    const res = await axiosReq.get(`/api/user/sites/${siteId}`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const createSite = async ({values}) => {
  try {
    const res = await axiosReq.post(`/api/user/sites/`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const updateSite = async ({values, siteId}) => {
  try {
    const res = await axiosReq.put(`/api/user/sites/${siteId}`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const deleteSite = async ({deleteId}) => {
  try {
    const res = await axiosReq.delete(`/api/user/sites/${deleteId}`);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}