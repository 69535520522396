import axiosReq from "utils/axiosConfig";
import { alertError, alertSuccess } from "./alert.service";

export const getCorporates = async () => {
  try {
    const res = await axiosReq.get(`/api/admin/corporates`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const getCorporate = async ({ queryKey }) => {
  const { corpId } = queryKey.length > 1 ? queryKey[1] : {};
  try {
    const res = await axiosReq.get(`/api/admin/corporates/${corpId}`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const createCorporate = async ({values}) => {
  try {
    const res = await axiosReq.post(`/api/admin/corporates/`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const updateCorporate = async ({values, corpId}) => {
  try {
    const res = await axiosReq.put(`/api/admin/corporates/${corpId}`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const deleteCorporate = async ({deleteId}) => {
  try {
    const res = await axiosReq.delete(`/api/admin/corporates/${deleteId}`);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}