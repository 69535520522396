import React, { useState, useEffect } from "react";
import { useMutation, useQuery, QueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { Container, Stack, Typography, Card } from '@mui/material';
import { isEmpty } from "lodash";

// components
import Page from 'components/Page';
// service
import { getCorporate, updateCorporate } from "services/adminCorporate.service";

import CorporateForm from "pages/shared/corporateForm";

const EditCorporateForm = () => {
  const queryClient = new QueryClient();
  const { id } = useParams();
  const { isSuccess, data } = useQuery(["corporate-edit", { corpId: id }], getCorporate);

  const [corporateState, setCorporateState] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      setCorporateState(data);
    }
  }, [isSuccess, data]);

  const submitCorp = useMutation(values => {
    return updateCorporate({values, corpId: id});
  });

  const onSubmitCorp = (values) => {
    submitCorp.mutate(values, {
      onSuccess: (res) => {
        if (res)
          queryClient.setQueriesData(["corporate-edit", { corpId: id }], res)
      },
      onSettled: () => {
        queryClient.invalidateQueries(["corporate-edit", { corpId: id }]);
      }
    });
  }

  return (
    <Page title="Corporate">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Edit Corporate
          </Typography>
        </Stack>

        <Card>
          {
            (isSuccess === true && !isEmpty(corporateState)) && (
              <CorporateForm 
                initialValues={corporateState}
                onSubmit={onSubmitCorp}
              />
            )
          }
        </Card>
      </Container>
    </Page>
  );
}

export default EditCorporateForm;