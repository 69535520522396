import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  TableContainer,
} from '@mui/material';
// components
import Page from 'components/Page';
import Scrollbar from 'components/Scrollbar';
import TableList from 'components/tableList';
import ModalConfirmation from 'components/modalConfirmation';
import { UserMoreMenu } from 'components/_dashboard/user';
// services
import { getEmployeeTypes, deleteEmployeeType } from "services/employeeType.service";

export default function EmployeeType() {
  const history = useHistory();
  const selectedOrg = useSelector((state) => state.userData?.orgId);

  const { isSuccess, data: resStatus } = useQuery(["employeeTypes", { organizationId: selectedOrg }], getEmployeeTypes);

  const [employeeTypes, setEmployeeTypes] = useState([]);
  const [deleteId, setDeletedId] = useState("");
  const [show, setShow] = useState(false);
 
  useEffect(() => {
    if (isSuccess) {
      setEmployeeTypes(resStatus?.data ?? []);
    }
  }, [isSuccess, resStatus]);

  const columnStatus = useMemo(() => {
    return [
      {
        name: "#",
        selector: (_, index) => index + 1,
        width: "80px"
      },
      {
        name: "Name",
        selector: row => row.name,
        sortable: true,
      },
      {
        name: "Action",
        button: true,
        cell: row => (
          <UserMoreMenu 
            onEdit={() => history.push(`/employeeType/edit/${row.uId}`)}
            onDelete={() => {
              setDeletedId(row.uId);
              setShow(true);
            }}
          />
        )
      }
    ]
  }, [history]);

  const souceStatus = useMemo(() => {
    return employeeTypes.map((statusEmployee, index) => (
      {
        key: index,
        uId: statusEmployee.uId,
        _id: statusEmployee._id,
        name: statusEmployee.name,
      }
    ))
  }, [employeeTypes]);

  const handleDelete = useCallback(async () => {
    const res = await deleteEmployeeType({deleteId});
    if (res) {
      setEmployeeTypes(employeeTypes.filter(x => x.uId !== deleteId));
    }

    setShow(false);
  }, [deleteId, employeeTypes]);

  return (
    <Page title="Employee Type">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Employee Type
          </Typography>

          
          <Button
            variant="contained"
            component={RouterLink}
            to="/employeeType/create"
            startIcon={<Icon icon={plusFill} />}
          >
            New Employee Type
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <TableList
                columns={columnStatus}
                data={souceStatus}
                isFilter={true}
              />
            </TableContainer>
          </Scrollbar>
        </Card>

        <ModalConfirmation 
          open={show}
          handleClose={() => setShow(false)}
          handleOk={() => handleDelete()}
        />
      </Container>
    </Page>
  );
}
