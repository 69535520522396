import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import { useMutation } from "react-query";
import { isEmpty } from "lodash";

import { verifyToken } from "services/auth.service";

const JumpAuthorize = () => {
  const { search } = useLocation();
  const history = useHistory();

  const [response, setResponse] = useState({});

  const searchParams = useMemo(
    () => new URLSearchParams(search),
    [search]
  );

  const bearerToken = searchParams.get("token");
  const splitOrg = search?.toString().split("organizationId=");
  const organizationId = splitOrg.length > 0 ? splitOrg[splitOrg.length - 1] : "";

  const serviceVerifyToken = useMutation(_ => {
    return verifyToken({
      data: {
        bearerToken: bearerToken,
        orgId: organizationId
      },
    })
  });

  useEffect(() => {
    serviceVerifyToken.mutate({}, {
      onSettled: (res) => {
        if (res) {
          console.log(res)
          setResponse(res);
        }
      }
    });
  }, [])

  useEffect(() => {
    if (!isEmpty(response)) {
      if (response.status === 200) {
        localStorage.setItem("accessToken", bearerToken);
        localStorage.setItem("currentUser", JSON.stringify({
          ...response.data,
          token: bearerToken,
          encryptOrgId: organizationId
        }));
  
        history.push("/");
      } else {
        history.push("/");
      }
    }
  }, [response])

  return (
    <div></div>
  );
};

export default JumpAuthorize;
