import axiosReq from "utils/axiosConfig";
import { alertError, alertSuccess } from "./alert.service";

export const getApps = async () => {
  try {
    const res = await axiosReq.get(`/api/admin/apps`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const getApp = async ({ queryKey }) => {
  const { corpId } = queryKey.length > 1 ? queryKey[1] : {};
  try {
    const res = await axiosReq.get(`/api/admin/apps/${corpId}`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const createApp = async ({values}) => {
  try {
    const res = await axiosReq.post(`/api/admin/apps/`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const updateApp = async ({values, corpId}) => {
  try {
    const res = await axiosReq.put(`/api/admin/apps/${corpId}`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const deleteApp = async ({deleteId}) => {
  try {
    const res = await axiosReq.delete(`/api/admin/apps/${deleteId}`);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}