import styled from "styled-components";

export const WorkdayButton = styled.div`
  padding: 10px;

  .MuiButton-root {
    display: inline-block;
  }

  .MuiButton-root.not-active {
    background-color: transparent;
    color: #637381;

    :hover {
      box-shadow: inherit;
    }
  }
`;

export const WorkdayWrapper = styled.div`
  .time-input {
    padding: 10px 0px;

    .MuiOutlinedInput-input {
      padding: 10px;   
    }
  }
  
  header {
    display: none;
  }
`;