import React, { useState, useEffect } from "react";
import { useMutation, useQuery, QueryClient } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import { Container, Stack, Typography, Card } from '@mui/material';
import { isEmpty } from "lodash";

// components
import Page from 'components/Page';
// service
import { getUser, updateUser } from "services/adminUser.service";
import { getCorporates } from "services/adminCorporate.service";
import { getApps } from "services/adminApp.service";
import { getLicenses } from "services/adminLicense.service";
import { fToInputDate } from "utils/formatTime";

import UserForm from "./UserForm";

const EditUserForm = () => {
  const queryClient = new QueryClient();
  const history = useHistory();

  const { id } = useParams();
  const { isSuccess: isSuccessUser, data: resUser } = useQuery(["user-edit", { userId: id }], getUser);
  const { isSuccess: isSuccessCorp, data: resCorp } = useQuery(["corporates"], getCorporates);
  const { isSuccess: isSuccessApp, data: resApp } = useQuery(["apps"], getApps); 
  const { isSuccess: isSuccessLicense, data: resLicense } = useQuery(["licenses", 
  { 
    ownerId: resUser?.organizations, 
    userAccessControl: resUser?.uId 
  }], 
  getLicenses, 
  {
    enabled: isSuccessUser
  });

  const [finsihLoadUser, setFinishLoadUser] = useState(false);
  const [finishLoad, setFinishLoad] = useState(false);

  const [userState, setUserState] = useState({});
  const [corps, setCorps] = useState([]);
  const [apps, setApps] = useState([]);

  useEffect(() => {
    if (isSuccessUser) {
      setUserState({
        ...resUser,
        dateOfBirth: resUser.dateOfBirth ? fToInputDate(resUser.dateOfBirth) : "",
        gender: resUser.gender === "" ? "man" : resUser.gender,
        country: resUser.country === "" ? "SG" : resUser.country
      });
      setFinishLoadUser(true);
    }
  }, [isSuccessUser, resUser]);

  useEffect(() => {
    if (isSuccessCorp) {
      setCorps(resCorp.map(item => (
        {
          value: item.uId,
          label: item.corpName
        }
      )));
    }
  }, [isSuccessCorp, resCorp]);

  useEffect(() => {
    if (isSuccessApp) {
      setApps(resApp.map(item => (
        {
          value: item.code,
          label: item.name
        }
      )));
    }
  }, [isSuccessApp, resApp]);

  useEffect(() => {
    if (!isEmpty(userState)) {
      if (resLicense) {
        const orgAccessControls = [];
        userState.organizations?.map((x, index) => {
          orgAccessControls[index] = [];
          const licenses = resLicense.filter(l => l.ownerId === x);

          // orgAccessControls[index].push({
          //   label: "Eprofile",
          //   startDate: fToInputDate(new Date()),
          //   expiredDate: fToInputDate(new Date())
          // })
  
          licenses.map(l => {
            orgAccessControls[index].push({
              label: l.appAccessControl,
              startDate: fToInputDate(l.startDate.toString()),
              expiredDate: fToInputDate(l.expiredDate.toString())
            })
          })
        });
        
        setUserState({
          ...userState,
          orgAccessControls
        });
  
        setFinishLoad(true);
      } else {
        setFinishLoad(true);
      }
    }
  }, [isSuccessLicense, resLicense, finsihLoadUser]);

  const submitUser = useMutation(values => {
    return updateUser({values, userId: id});
  });

  const onSubmitUser = (values) => {
    submitUser.mutate(values, {
      onSuccess: (res) => {
        if (res) {
          console.log("back to previus page");
          history.goBack();
          queryClient.setQueriesData(["user-edit", { userId: id }], res)
        }
      }
    });
  }

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Edit User
          </Typography>
        </Stack>

        <Card>
          {
            (finishLoad === true && !isEmpty(userState)) && (
              <UserForm 
                initialValues={{
                  ...userState,
                  password: "",
                  confirmPassword: "",
                }}
                corps={corps}
                apps={apps}
                isEdit={true}
                onSubmit={onSubmitUser}
              />
            )
          }
        </Card>
      </Container>
    </Page>
  );
}

export default EditUserForm;