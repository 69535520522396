import React from "react";
import { useSelector } from "react-redux";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { Container, Stack, Typography, Card } from '@mui/material';

// components
import Page from 'components/Page';
// services
import { createEmployeeType } from "services/employeeType.service";

import EmployeeTypeForm from "./EmployeeTypeForm";

const CreateStatusForm = () => {
  const history = useHistory();
  const selectedOrg = useSelector((state) => state.userData?.orgId);

  const submitStatus = useMutation(values => {
    return createEmployeeType({values: {...values, organizationId: selectedOrg}});
  });

  const onSubmitStatus = (values) => {
    submitStatus.mutate(values, {
      onSuccess: (res) => {
        if (res) {
          history.goBack();
        }
      }
    });
  }

  return (
    <Page title="Employee Type">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Create Employee Type
          </Typography>
        </Stack>

        <Card>
          <EmployeeTypeForm 
            onSubmit={onSubmitStatus}
          />
        </Card>
      </Container>
    </Page>
  );
}

export default CreateStatusForm;