import axiosReq from "utils/axiosConfig";
import { alertError, alertSuccess } from "./alert.service";

export const getDepartments = async ({ queryKey }) => {
  try {
  const { organizationId } = queryKey.length > 1 ? queryKey[1] : {};
    const res = await axiosReq.get(`/api/user/departments`, {
      params: {
        organizationId
      }
    });
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const getDepartment = async ({ queryKey }) => {
  const { departmentId } = queryKey.length > 1 ? queryKey[1] : {};
  try {
    const res = await axiosReq.get(`/api/user/departments/${departmentId}`);
    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const createDepartment = async ({values}) => {
  try {
    const res = await axiosReq.post(`/api/user/departments/`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const updateDepartment = async ({values, departmentId}) => {
  try {
    const res = await axiosReq.put(`/api/user/departments/${departmentId}`, values);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}

export const deleteDepartment = async ({deleteId}) => {
  try {
    const res = await axiosReq.delete(`/api/user/departments/${deleteId}`);
    alertSuccess("Success");

    return res.data;
  } catch(err) {
    alertError(err.response?.data?.message ?? "");
  }
}