import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import JumpAuthorize from 'pages/jumpAuthorize';
import Login from 'pages/user/login';
import Register from 'pages/user/register';
// basic
import ForgetPassword from 'pages/user/forgetPassword';
import VerifyCode from 'pages/user/forgetPassword/verifyCode';
import ResetPassword from 'pages/user/forgetPassword/resetPassword';
import Account from 'pages/user/account';
// user
import PageUser from 'pages/user/orgUser';
import PageUserCreate from 'pages/user/orgUser/Create';
import PageUserEdit from 'pages/user/orgUser/Edit';
// department
import PageDepartment from 'pages/user/department';
import PageDepartmentCreate from 'pages/user/department/Create';
import PageDepartmentEdit from 'pages/user/department/Edit';
// holiday
import PageHoliday from 'pages/user/holiday';
import PageHolidayCreate from 'pages/user/holiday/Create';
import PageHolidayEdit from 'pages/user/holiday/Edit';
// site
import PageSite from 'pages/user/site';
import PageSiteCreate from 'pages/user/site/Create';
import PageSiteEdit from 'pages/user/site/Edit';
// employee Type
import PageEmployeeType from 'pages/user/employeeType';
import PageEmployeeTypeCreate from 'pages/user/employeeType/Create';
import PageEmployeeTypeEdit from 'pages/user/employeeType/Edit';
// corporate setting
import PageCorporateSetting from "pages/user/corporate";
import PageUserActivateAccount from 'pages/admin/app/ActivateAccount';

import ProtectedRoute from "./protected.route";

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/forgetpassword" component={ForgetPassword} />
        <Route exact path="/verifycode" component={VerifyCode} />
        <Route exact path="/resetpassword" component={ResetPassword} />
        <Route exact path="/middlepage" component={JumpAuthorize} />
        <Route exact path="/activateaccount/:id" component={PageUserActivateAccount} />

        <ProtectedRoute exact path="/" role="user" component={PageUser} />
        <ProtectedRoute exact path="/account" role="user" component={Account} />
        <ProtectedRoute exact path="/user" role="admin" component={PageUser} />
        <ProtectedRoute exact path="/user/create" role="admin" component={PageUserCreate} />
        <ProtectedRoute exact path="/user/edit/:id" role="admin" component={PageUserEdit} />

        <ProtectedRoute exact path="/department" role="user" component={PageDepartment} />
        <ProtectedRoute exact path="/department/create" role="user" component={PageDepartmentCreate} />
        <ProtectedRoute exact path="/department/edit/:id" role="user" component={PageDepartmentEdit} />

        <ProtectedRoute exact path="/holiday" role="user" component={PageHoliday} />
        <ProtectedRoute exact path="/holiday/create" role="user" component={PageHolidayCreate} />
        <ProtectedRoute exact path="/holiday/edit/:id" role="user" component={PageHolidayEdit} />

        <ProtectedRoute exact path="/site" role="user" component={PageSite} />
        <ProtectedRoute exact path="/site/create" role="user" component={PageSiteCreate} />
        <ProtectedRoute exact path="/site/edit/:id" role="user" component={PageSiteEdit} />

        <ProtectedRoute exact path="/employeeType" role="user" component={PageEmployeeType} />
        <ProtectedRoute exact path="/employeeType/create" role="user" component={PageEmployeeTypeCreate} />
        <ProtectedRoute exact path="/employeeType/edit/:id" role="user" component={PageEmployeeTypeEdit} />

        <ProtectedRoute exact path="/corporate" role="user" component={PageCorporateSetting} />
      </Switch>
    </Router>
  );
}

export default AppRouter;